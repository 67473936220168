import React from "react";
import {
  Button,
  Card,
  Input,
  Menu,
  Modal,
  Tag,
  Dropdown,
  notification,
  message,
  Spin,
  Tabs,
  Radio,
  Select
} from "antd";
import moment from "moment";
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { Form, Icon } from "@ant-design/compatible";
import Highlighter from "react-highlight-words";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import CustomDataTable from "../../../../components/CustomDataTable";
import AddProductModal from "./_partials/AddProductModal";
import EditProductModal from "./_partials/EditProductModal";
import DeleteProductModal from "./_partials/DeleteProductModal";
import BulkDeleteProductModal from "./_partials/BulkDeleteProductModal";

import SendInventoryModal from "./_partials/SendInventoryModal";
import UpdateInventoryModal from "./_partials/UpdateInventoryModal";
import ProductsBarCode from "./_partials/ProdctsBarCode";
import AddCategoryModal from "./_partials/AddCategoryModal";
import BulkSendInventoryModal from "./_partials/BulkSendInventoryModal";
import ViewDetailsModal from "./_partials/ViewDetailsModal";
import { getShock } from "./functions";
import numberFormatter from "../../../../utils/numberFormatter";
import { debounce } from 'lodash';

// Redux
import { connect } from "react-redux";
import {
  getPageProducts,
  getAllProducts,
  getAllBranchProducts,
  getProductsByCategory,
  getSingleProductHistory,
  getProductHistory
} from "../../../../redux/actions/productActions";
import {
  addNewBusinessBranch,
  getAllBusinessBranches,
} from "../../../../redux/actions/branchActions";
import WithApolloClient from "../../../../utils/withApolloClient";
import { getAllCategories } from "../../../../redux/actions/categoryActions";
import { getSuppliers, getProductsCpAndSp, getBranchInventory } from "../../../../redux/actions/productActions";
import { getAllLoyaltyPrograms } from "../../../../redux/actions/loyaltyActions";
import ImportProductModal from "./_partials/ImportProductModal";
import ViewCategoryModal from "./_partials/ViewCategoryModal";
import EditCategoryModal from "./_partials/EditCategoryModal";
import DeleteCategoryModal from "./_partials/DeleteCategoryModal";
import AddLoyaltyProgram from "./_partials/AddLoyaltyProgram";
import AddSupplierModal from "./_partials/AddSupplierModal";
import AddProductSupplies from "./_partials/AddProductSuppliesModal";
import ViewSuppliersModal from "./_partials/ViewSuppliersModal";
import CloneProductModal from "./_partials/cloneProductModal";
import PriceChecker from "./_partials/pricechecker";
import { GET_MERCHANT_SENDERID } from "../Apps/MultiLevelLoyalty/Loyalties/constants";
import { useQuery } from "@apollo/client";
import BulkUpdateModal from "./_partials/BulkUpdateModal";

// Page UI styles
import "./index.scss";
import strings from "../../../../strings";
import api from "../../../../api";
import AddBundleModalForm from "./_partials/bundleModal";
import ShopLinkModal from "./_partials/ShopLinkModal";
import BulkQuantityEdit from "./_partials/BulkQuantityEdit";
import ReturnedItems from "./_partials/ReturnedItems";
import DamagedItems from "./_partials/DamagedItems";
import CreatePurchaseOrder from "./_partials/CreatePurchaseOrder";
import AcceptPurchaseOrder from "./_partials/AcceptPurchaeOrder";
import ProductHistory from "./_partials/ProductHistory";

class Products extends React.PureComponent {
  state = {
    isAddProductModalVisible: false,
    addProductSku: false,
    currentActionRecord: null,
    isEditProductModalVisible: false,
    isDeleteModalVisible: false,
    isSendInventoryVisible: false,
    isAddCategoryVisible: false,
    isViewProductVisible: false,
    isViewCategoryVisible: false,
    isEditCategoryModalOpen: false,
    isDeleteCategoryModalOpen: false,
    products: [],
    importType: "",
    total_selling_price: "",
    total_cost_price: "",
    total_products: 0,
    pagination: { current: 1, defaultPageSize: 10, pageSize: 10 },
    fetchedPages: [],
    loadingProducts: false,
    productSearchText: "",
    isAddLoyaltyProgramModalVisible: false,
    isUpdateInventoryVisible: false,
    selectedRowKeys: [],
    isBulkDeleteModalVisible: false,
    isImportModalVisible: false,
    isViewSuppliersModalVisible: false,
    isProductHistoryModalVisible: false,
    isAddSuppliersModalVisible: false,
    isViewProductSuppliesModalVisible: false,
    isAddProductSuppliesModalVisible: false,
    isSuppliersLoading: false,
    product_sku: "",
    product_sku2: "",
    allProducts: [],
    isBulkSendInventoryModal: false,
    openPriceChecker: false,
    isProductCpAndSploading: false,
    shouldShowCpandSp: false,
    addBundleModal: false,
    bundleProducts: [],
    uRewardID: null,
    showBulkProductEdit: false,
    showBulkQuantityEdit: false,
    productToClone: false,
    sortProduct: [],
    isCloneProductModalOpen: false,
    openShopLinkModal: false,
    inventoryValue: null,
    totalProductCount: null,
    pageNumber: 1,
    pageSize: 10,
    selectedCategory: null,
    fetchCustLoading: false,
    isShowReturnModal: false,
    isShowDamagedModal: false,
    createPurchaseOrder: false,
    acceptPurchaseOrder: false,
    supplierLoading: false
  };

  async componentDidMount() {
    const { getAllCategories, getAllLoyaltyPrograms, getAllBusinessBranches } =
      this.props;
    const { getAllBundleProduct } = this;
    const {
      auth: { user },
    } = this.props;

    const {
      data: { getMerchantSenderId },
    } = await this.props.client.query({
      query: GET_MERCHANT_SENDERID,
      variables: {merchant_id: user.id},
      onError: (error) => {
        message.error(error.message, 5);
      },
    });

    this.setState({ uRewardID: getMerchantSenderId && getMerchantSenderId.uRewardsId });
    
    getAllBusinessBranches();
    this.getPageProducts(this.state.pagination.current);
    getAllCategories();
    getAllLoyaltyPrograms();
    this.getAllProducts();
    getAllBundleProduct();
    this.fetchSuppliers()
  }

  getAllBundleProduct = async () => {
    const { user } = this.props.auth;
    const response = await api.product.getAllMerchantBundleProducts(user.id);
    if(response.status === 200){
      this.setState({ bundleProducts: response.data })
    }

  }

  getProductCpandSp = async () => {
    const { getProductsCpAndSp } = this.props;
    this.setState({ isProductCpAndSploading: true });

    await getProductsCpAndSp().then((res)=>{
      if(res.status === 200){
        this.setState({ 
          total_cost_price: res.headers["total-cost-price"],
          total_selling_price: res.headers["total-selling-price"]
        });

        this.setState({ isProductCpAndSploading: false })
      }
    })
    .catch((err)=>{
      console.log(err);
    })   
  }

  getBranchInventoryValues = async () => {
    const { getBranchInventory } = this.props;
    this.setState({ isProductCpAndSploading: true });
    const { branchId } = this.props.match.params;

    await getBranchInventory(branchId).then((res)=>{
      if(res.status === 200){
        this.setState({ 
          inventoryValue: res.data.inventory_value,
        });

        this.setState({ isProductCpAndSploading: false })
      }
    })
    .catch((err)=>{
      console.log(err);
    }) 
  }

  getPageProducts = (page, pageSize) => {
    const {
      match,
      branchProducts,
      auth: { user },
    } = this.props;
    const { branchId } = match.params;

    if (!isNaN(branchId)) {
      // if (branchProducts.length < 1) {
      //   this.setState({ loadingProducts: true });
      //   this.setState({ branchId });
      //   this.fetchAllBranchProducts(user.id, branchId, page, pageSize);
      // } else {
      //   this.setState({ products: branchProducts });
      // }
      const user_id = user?.employer?.id ? user.employer.id : user.id;
      this.fetchAllBranchProducts(user_id, branchId, page, pageSize);
    } else {
      this.setState({ loadingProducts: true });
      this.fetchProducts(page, pageSize).then((res) => {
        
        this.setState({
          loadingProducts: false,
          products: res.data,
          // total_selling_price: res.headers["total-selling-price"],
          // total_cost_price: res.headers["total-cost-price"],
          total_products: Number(res.headers.total),
          pagination: {
            current: Number(res.headers.page),
            pageSize: Number(res.headers["per-page"]),
            total: Number(res.headers.total),
          },
        });
      });
    }
    
    // this.getProductCpandSp();
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      auth: { user },
      match: {
        params: { branchId },
      },
    } = this.props;

    if (!branchId && !this.state.branchId && !prevState.branchId) {
      this.setState({
        branchId: "all",
      });
    }

    if (
      prevState.branchId !== this.props.match.params.branchId &&
      this.state.branchId !== "all"
    ) {
      this.setState({
        branchId,
      });
      const user_id = user?.employer?.id ? user?.employer.id : user.id
      return this.fetchAllBranchProducts(user_id, branchId);
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   const {
  //     error,
  //     products,
  //     match: {
  //       params: { branchId },
  //     },
  //   } = props || {};

  //   if (state.branchId === "all" || branchId === "all") {
  //     return {
  //       branchId: state.branchId || branchId,
  //       products,
  //     };
  //   }

  //   if (state.branchId && state.branchId !== branchId) {
  //     return {
  //       branchId,
  //       products,
  //     };
  //   }

  //   if (error) {
  //     return {
  //       products,
  //     };
  //   }

  //   if (products.length >= 0) {
  //     return { products };
  //   }

  //   // Return null if the state hasn't changed
  //   return null;
  // }

  fetchProducts = (
    page = this.state.pagination.current,
    pageSize = this.state.pagination.pageSize
  ) => {

    this.setState({ loadingProducts: true });
    this.getProductCpandSp()
    return this.props.getPageProducts(page, pageSize).then((res) => {
      this.setState({ loadingProducts: false, products: res.data });
      return res;
    });
  };

  
  fetchAllBranchProducts = (
    id = this.props.auth?.user?.employer?.id ? this.props.auth?.user?.employer.id : this.props.auth?.user.id,
    branchId = this.props.match.params.branchId,
    pageNumber=1,
    pageSize=10,
    category
  ) => {
    const { auth } = this.props;
    this.setState({ loadingProducts: true });
    this.getBranchInventoryValues();
    return this.props
    .getAllBranchProducts(id, branchId, pageNumber, pageSize)
    .then((res) => {
      this.setState({ loadingProducts: false });
      if (res.status === 200) {
        this.setState({ 
          loadingProducts: false, 
          products: res.data,
          pagination: {
            current: pageNumber,
            pageSize: pageSize,
            total: Number(res.headers["total-product-count"]),
          }, 
          total_products: Number(res.headers["total-product-count"])
        });
      }
    })
    .catch(err => {
      console.log("product err", err);
    })
  };

  fetchSuppliers = async () => {
    try {
      this.setState({ supplierLoading: true })
      const res = await this.props.getSuppliers();

      if (res.status === 200) {
        this.setState({ supplierLoading: false })
      }
    } catch (error) {
      console.log(error);
      this.setState({ supplierLoading: false })
    }
};

  toggleAddProductModal = () => {
    this.setState({
      isAddProductModalVisible: !this.state.isAddProductModalVisible,
    });
  };

  toggleViewSuppliersModal = () => {
    this.setState({
      isViewSuppliersModalVisible: !this.state.isViewSuppliersModalVisible,
    });
  };

  getAllProducts = () => {
    this.setState({ fetchCustLoading: true });
    this.props.getAllProducts(1, 5000).then((res) => {
      if (res.status === 200) {
        // console.log("products", res);
        this.setState({ allProducts: res.data, fetchCustLoading: false });
      }
    });
  };

  toggleAddSupplierModal = () => {
    this.setState({
      isAddSuppliersModalVisible: !this.state.isAddSuppliersModalVisible,
    });
  };

  toggleViewProductSuppliesModal = () => {
    this.setState({
      isViewProductSuppliesModalVisible:
        !this.state.isViewProductSuppliesModalVisible,
    });
  };

  toggleBulkProductEditModal = () => {
    this.setState({ showBulkProductEdit: !this.state.showBulkProductEdit })
  }

  toggleBulkQuantityEditModal = () => {
    this.setState({ showBulkQuantityEdit: !this.state.showBulkQuantityEdit })
  }

  toggleAddProductSuppliesModal = () => {
    this.setState({
      isAddProductSuppliesModalVisible:
        !this.state.isAddProductSuppliesModalVisible,
    });
  };

  toggleEditProductModal = () => {
    this.setState({
      isEditProductModalVisible: !this.state.isEditProductModalVisible,
    });
  };

  toggleDeleteProductModal = () => {
    this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible });
  };

  toggleBulkDeleteModal = () => {
    this.setState({
      isBulkDeleteModalVisible: !this.state.isBulkDeleteModalVisible,
    });
  };

  toggleSendInventoryModal = () => {
    this.setState({
      isSendInventoryVisible: !this.state.isSendInventoryVisible,
    });
  };

  toggleUpdateInventoryModal = () => {
    this.setState({
      isUpdateInventoryVisible: !this.state.isUpdateInventoryVisible,
    });
  };

  toggleViewCategoryModal = () => {
    this.setState({
      isViewCategoryVisible: !this.state.isViewCategoryVisible,
    });
  };

  toggleEditCategoryModal = (currCategory) => {
    this.setState({ currCategory });
    this.setState({
      isEditCategoryModalOpen: !this.state.isEditCategoryModalOpen,
    });
  };

  toggleDeleteCategoryModal = (currCategory) => {
    this.setState({ currCategory });
    this.setState({
      isDeleteCategoryModalOpen: !this.state.isDeleteCategoryModalOpen,
    });
  };

  toggleAddCategoryModal = () => {
    this.setState({
      isAddCategoryVisible: !this.state.isAddCategoryVisible,
    });
  };

  toggleViewProductModal = (rowData = null) => {
    this.setState({
      currentActionRecord: rowData,
      isViewProductVisible: !this.state.isViewProductVisible,
    });
  };

  toggleImportProductModal = () => {
    this.setState({
      isImportModalVisible: !this.state.isImportModalVisible,
    });
  };

  setImportType = (type) => {
    this.setState({ importType: type });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${strings.search} ${dataIndex}`}
          value={this.state.productSearchText}
          onChange={(e) => {
            this.setState({ productSearchText: e.target.value });
            setSelectedKeys(e.target.value ? [e.target.value] : [""]);
          }}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <div>
          <div>
            <Button
              type="primary"
              tabIndex={1}
              // onClick={() => this.handleSearch(selectedKeys, confirm)}
              onClick={() => this.handleSearchAllPages(selectedKeys)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              {strings.search}
            </Button>
            <Button
              onClick={() => {
                setSelectedKeys([""]);
                this.handleReset(clearFilters, confirm);
              }}
              size="small"
              style={{ width: 90 }}
            >
              {strings.reset}
            </Button>
          </div>
          {/* <div>
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 5, width: "100%" }}
              onClick={() => this.handleSearchAllPages(selectedKeys)}
            >
              Search All Pages 
          </div> */}
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.productSearchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ productSearchText: selectedKeys[0] });
  };

  handleSearchAllPages = (selectedKeys) => {
    const { branchId } = this.state;
    const { user } = this.props.auth;
    const user_id = user?.employer?.id ? user.employer.id : user.id;
    if (!selectedKeys[0])
      return isNaN(Number(branchId))
        ? this.fetchProducts()
        : this.fetchAllBranchProducts(user_id, branchId);
    this.setState({ loadingProducts: true });
    api.HttpClient.get(`/search_product/${selectedKeys[0]}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            loadingProducts: false,
            products: res.data.filter((product) => product.deleted === false),
          });
        } else this.setState({ loadingProducts: false, products: [] });
      })
      .catch((err) => {
        this.setState({ loadingProducts: false });
        message.error(err.response?.data.message || err.message);
      });
  };

  handleReset = (clearFilters, confirm) => {
    const { branchId } = this.props.match.params;
    const { user } = this.props.auth;
    const user_id = user?.employer?.id ? user.employer.id : user.id;
    confirm();
    clearFilters();
    this.setState({ productSearchText: "" });
    return isNaN(Number(branchId))
      ? this.getPageProducts(
          this.state.pagination.current,
          this.state.pagination.pageSize
        )
      : this.fetchAllBranchProducts(user_id, branchId);
  };

  handleToggleLoyaltyProgramModal = () => {
    this.setState({
      isAddLoyaltyProgramModalVisible:
        !this.state.isAddLoyaltyProgramModalVisible,
    });
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onChangeTablePage = (newPagination, filters) => {
    const { selectedCategory } = this.state;
    const { branchId } = this.props.match.params;
    const { user } = this.props.auth;
    const user_id = user?.employer?.id ? user.employer.id : user.id
    if(selectedCategory) return this.handleGetProductByCategory(selectedCategory, newPagination.current)
    if (isNaN(Number(branchId))) {
      if (filters.name && filters?.name[0])
        return this.handleSearchAllPages(filters.name);
        return this.getPageProducts(
          newPagination.current,
          newPagination.pageSize
        );
    }else{
      this.fetchAllBranchProducts(user_id, branchId,  newPagination.current, newPagination.pageSize);
    }
    this.setState({ pagination: newPagination });
  };

  sendBulkInventory = () => {
    if (this.state.selectedRowKeys.length < 2) {
      return Modal.error({
        title: "Kindly select more than one product to send bulk inventory",
      });
    }
    this.setState({ isBulkSendInventoryModal: true });
  };

  addBundleProduct = () => {
    this.setState({ addBundleModal: true })
  }

  openThePriceChecker = () => {
    this.setState({ openPriceChecker: true })
  }

  handleProductImport = () => {
    const {
      products,
      auth: {
        user: { subscription_plan },
      },
    } = this.props;

    const allLoyaltyPrograms = this.props.allLoyaltyPrograms.filter(
      (loyalty) => loyalty.deleted !== true
    );

    // Check if Merchant has a Loyalty Program
    if (allLoyaltyPrograms.length > 0) {
      if (subscription_plan === "Free") {
        if (products.length > 20) {
          Modal.warn({
            title: strings.youHaveExceededTotalNumberOfProducts,
          });
        } else {
          this.setImportType("Simple Product");
          this.toggleImportProductModal();
        }
      } else if (subscription_plan === "Basic") {
        if (products.length > 60) {
          Modal.warn({
            title: strings.youHaveExceededTotalNumberOfProducts,
          });
        } else {
          this.setImportType("Simple Product");
          this.toggleImportProductModal();
        }
      } else if (subscription_plan === "Pro") {
        this.setImportType("Simple Product");
        this.toggleImportProductModal();
      } else if (subscription_plan === "Basic Plus") {
        this.setImportType("Simple Product");
        this.toggleImportProductModal();
      } else if (subscription_plan === "Pro Plus") {
        this.setImportType("Simple Product");
        this.toggleImportProductModal();
      }
    } else {
      this.setImportType("Simple Product");
      this.toggleImportProductModal();
    }
  };

  handleCloneProduct = () => {
    const { selectedKeys, selectedRowKeys, sortProduct } = this.state;

    const selectedProduct = sortProduct.length > 0 && sortProduct.filter(prod => prod.id === selectedRowKeys[0]);

    this.setState({ 
      productToClone: selectedProduct[0], 
      currentActionRecord: selectedProduct[0],
      isCloneProductModalOpen: true
    });
  }

  handleDisplayReturns = () => {

  }

  handleGetProductByCategory = (id, pageNum) => {
    this.setState({
      loadingProducts: true,
      selectedCategory: id
    });

    let page = pageNum ? pageNum : this.state.pagination.current;
    let pageSize =  this.state.pagination.pageSize;
    const branchId = this.props.match.params.branchId;
    if(isNaN(Number(branchId))){
      return this.props.getProductsByCategory(
        page,
        pageSize,
        id
      )
      .then(res => {
        if(res.status === 200){
          this.setState({ 
            loadingProducts: false, 
            products: res.data,
            pagination: {
              current: page,
              pageSize: pageSize,
              total: Number(res.headers["total"]),
            }, 
            total_products: Number(res.headers["total"])
          });
        }
      })
      .catch(err => {
        this.setState({
          loadingProducts: false
        });
      })
    }

    const user_id = this.props.auth?.user?.employer?.id ? this.props.auth?.user?.employer.id : this.props.auth?.user.id;
    

    return this.props
    .getAllBranchProducts(user_id, branchId, page, pageSize, id)
    .then((res) => {
      this.setState({ loadingProducts: false });
      if (res.status === 200) {
        this.setState({ 
          loadingProducts: false, 
          products: res.data,
          pagination: {
            current: page,
            pageSize: pageSize,
            total: Number(res.headers["total-product-count"]),
          }, 
          total_products: Number(res.headers["total-product-count"])
        });
      }
    })
    .catch(err => {
      console.log("product err", err);
    })
    
//  page = this.state.pagination.current,
// pageSize = this.state.pagination.pageSize
  }

  render() {
    const {
      auth: { user },
      getAllLoyaltyPrograms,
      branches,
    } = this.props;

    const currentBranch = branches.find(
      (branch) => branch.id === Number(this.state.branchId)
    );
    const myBranch =
      currentBranch || branches.find((branch) => branch.name === "Home Branch");

    const allCategories = this.props.allCategories.filter(
      (categories) => categories.deleted !== true
    );
    const allLoyaltyPrograms = this.props.allLoyaltyPrograms.filter(
      (loyalty) => loyalty.deleted !== true
    );

    const {
      isViewSuppliersModalVisible,
      isProductHistoryModalVisible,
      isAddSuppliersModalVisible,
      isAddProductSuppliesModalVisible,
      isViewCategoryVisible,
      isEditCategoryModalOpen,
      isDeleteCategoryModalOpen,
      isAddProductModalVisible,
      isAddCategoryVisible,
      currentActionRecord,
      isEditProductModalVisible,
      isDeleteModalVisible,
      isBulkDeleteModalVisible,
      isSendInventoryVisible,
      isViewProductVisible,
      isImportModalVisible,
      loadingProducts,
      products,
      isAddLoyaltyProgramModalVisible,
      isUpdateInventoryVisible,
      branchId,
      selectedRowKeys,
      importType,
      isBulkSendInventoryModal,
      openPriceChecker,
      isProductCpAndSploading,
      shouldShowCpandSp,
      addBundleModal,
      showBulkProductEdit,
      showBulkQuantityEdit,
      productToClone,
      isCloneProductModalOpen,
      inventoryValue,
      selectedCategory,
      fetchCustLoading,
      allProducts,
      createPurchaseOrder,
      acceptPurchaseOrder
    } = this.state;

    const { 
      isNitroActive, 
      isProductBundleActive, 
      isPurchaseOrderActive, 
      suppliers,
      getProductHistory,
      getSingleProductHistory 
    } = this.props;

    const branchProductsObj = {};
    this.props.branchProducts.forEach(
      (prod) => (branchProductsObj[prod.id] = prod)
    );

    const RadioGroup = Radio.Group;

    // const displayProducts = products.filter(
    //   (prod) => !branchProductsObj[prod.id]
    // );

  
    const sortedProducts = isNaN(Number(branchId))
      ? this.state?.products && this.state?.products?.sort((a, b) => a.name.localeCompare(b.name))
      : this.state?.products && this.state?.products?.sort((a, b) => a.name.localeCompare(b.name));

    this.setState({ sortProduct: sortedProducts })

    const columns = [
      {
        title: strings.name,
        dataIndex: "name",
        key: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: strings.price,
        dataIndex: "price",
        key: "price",
      },
      {
        title: strings.category,
        dataIndex: "merchant_product_category_id",
        render: (record) => {
          const category =
            allCategories &&
            allCategories.filter((category) => category.id === record);
          return category && Array.isArray(category)
            ? category[0] && category[0].name
            : category;
        },
        // filters:
        //   Array.isArray(allCategories) &&
        //   allCategories.map((category) => ({
        //     text: category.name,
        //     value: category.id,
        //   })),
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <div style={{ width: 140, padding: 4 }}>
              <RadioGroup 
                style={{display: "flex", flexDirection: "column", height: "150px", overflowY:"scroll", width: "100%"}}
                onChange={(e) => {
                  this.setState({ selectedCategory: e.target.value })
                  setSelectedKeys([e.target.value])
                }}
                // defaultValue={selectedKeys[0]}
              >
                {Array.isArray(allCategories) &&
                  allCategories.map((category) => {
                    return (
                      <Radio value={category.id} key={category.id}>{category.name}</Radio>
                    )
                  })}
              </RadioGroup>
              <div>
                <div style={{display:"flex", alignItems:"center", gap: 4}}>
                  <Button
                    onClick={() => {
                      setSelectedKeys(null);
                      this.setState({ selectedCategory: null })
                      this.handleReset(clearFilters, confirm);
                    }}
                    size="small"
                    style={{ width: 90 }}
                    type="text"
                  >
                    {strings.reset}
                  </Button>
                  <Button
                    type="primary"
                    tabIndex={1}
                    // onClick={() => this.handleSearch(selectedKeys, confirm)}
                    onClick={() => this.handleGetProductByCategory(selectedKeys[0])}
                    // icon={<SearchOutlined />}
                    size="small"
                    style={{ marginRight: 8 }}
                  >
                    ok
                  </Button>
                </div>
              </div>
            </div>
          //   <div style={{ padding: 8, display: "flex", flexDirection: "column", gap:5 }}>
          //   <Select
          //     placeholder={strings.cust}
          //     onChange={(value) =>{
          //       console.log("value", value);
                
          //        this.handleGetProductByCategory(value);
          //     }}
          //     optionFilterProp="children"
          //     // filterOption={(input, option) => {
          //     //   return (
          //     //     option.children.toString().indexOf(input.toLowerCase()) >= 0);
          //     // }}
          //     loading={this.state.searchLoading}
          //     value={selectedKeys[0] || this.state.selectedCustId}
          //     // onSearch={(value) => this.handleDebounce(value)}
          //     // ref={(node) => {
          //     //   this.searchInput = node;
          //     // }}
          //     showSearch
          //     allowClear
          //   >
          //     {/* {this.state.customerSearchedFor && this.state.customerSearchedFor.map((customer) => {
          //       return <Option key={customer.id}>{customer.first_name.toLowerCase()} {customer.last_name && customer.last_name.toLowerCase()}</Option>
          //     })} */}
          //     {/* {Array.isArray(allCategories) &&
          //       allCategories.map((category) => {
          //         return (
          //           <Radio value={category.id} key={category.id}>{category.name}</Radio>
          //         )
          //       })} */}
          //       {Array.isArray(allCategories) &&
          //       allCategories.map((category) => {
          //         return (
          //           <Select.Option key={category.id}>{category.name}</Select.Option>
          //         )
          //       })}
          //   </Select>
          //   <div>
          //     <Button
          //       type="primary"
          //       onClick={() => this.handleGetProductByCategory(selectedKeys[0])}
          //       icon={<SearchOutlined />}
          //       size="small"
          //       style={{ width: 90, marginRight: 8 }}
          //     >
          //       {strings.filter}
          //     </Button>
          //     <Button
          //       onClick={() => {
          //         setSelectedKeys(null);
          //         this.setState({ selectedCategory: null })
          //         this.handleReset(clearFilters, confirm);
          //       }}
          //       size="small"
          //       style={{ width: 90 }}
          //     >
          //       {strings.reset}
          //     </Button>
          //   </div>
           
          // </div>
          ),
        onFilter: (value, record) => {
          // console.log(value, record);
          // return this.handleGetProductByCategory(value);
          // return (
          //   String(record.merchant_product_category_id).indexOf(value) === 0
          // );
          // return value === record.merchant_product_category_id;
        },
        sorter: (a, b) => a.name.length - b.name.length,
        filterMultiple: false,
      },
      {
        title: strings.loyaltyPrograms,
        dataIndex: "merchant_loyalty_program_id",
        render: (record) => {
          const loyalty =
            allLoyaltyPrograms &&
            allLoyaltyPrograms.filter((loyalty) => record === loyalty.id);

          return loyalty && Array.isArray(loyalty)
            ? loyalty[0] && loyalty[0].name
            : loyalty;
        },
      },
      {
        title: strings.stockTracking,
        dataIndex: "track_inventory",
        filters: [
          {
            text: "Show products with inventory only",
            value: true,
          },
          {
            text: "Show products without inventory only",
            value: false,
          },
        ],
        render: (record) => (record ? <span>Yes</span> : <span>No</span>),
        onFilter: (value, record) => {
          return (
            (record.track_inventory ? "true" : "false").indexOf(value) === 0
          );
        },
        sorter: (a, b) => a.name.length - b.name.length,
        filterMultiple: false,
      },
      {
        title: strings.stockCount,
        dataIndex: "quantity",
        render: (record) => {
          return getShock(record);
        },
      },
      {
        title: strings.createdDate,
        dataIndex: "created_at",
        render: (record) => (record ? <span>{moment(record).format("YYYY-MM-DD")}</span> : <span>N/A</span>),
        sorter: (a, b) => {
          return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        },
      },
      // {
      //   title: strings.taxed,
      //   dataIndex: "tax",
      //   render: (record) => (record ? <span>Yes</span> : <span>No</span>),
      // },
      // {
      //   title: strings.publish,
      //   dataIndex: "publish_to_loystar_shop",
      //   key: "publish_to_loystar_shop",
      //   render: (record) => (record ? <span>Yes</span> : <span>No</span>),
      // },
    ];

    const isAdminIsManager =
      user.role_id === 1 || (user.role && user.role.id === 2);

    const isAdmin = user.role_id === 1;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const selectOne = selectedRowKeys.length === 1;

    const exportableProductList = this.state.allProducts.map((product) => {
      const { variants } = product;

      const orderedVariants =
        variants.length &&
        variants.map((variant) => {
          const { type, value, price, quantity } = variant;

          const stringVariant = `[${type} - ${value} - ${price} - ${quantity}],  `;

          return stringVariant;
        });

      return {
        ...product,
        orderedVariants: orderedVariants.toString(),
        sku: product.sku || product.product_sku,
        category: (() => {
          const category =
            allCategories &&
            allCategories.filter(
              (category) => category.id === product.merchant_product_category_id
            );
          return category && Array.isArray(category)
            ? category[0] && category[0].name
            : category;
        })(),
      };
    });

    const acceptedHeaders = [
      {
        label: strings.name,
        key: "name",
      },
      {
        label: strings.quantity,
        key: "quantity",
      },
      {
        label: strings.price,
        key: "price",
      },
      {
        label: strings.costPrice,
        key: "cost_price",
      },
      {
        label: strings.originalPrice,
        key: "original_price",
      },
      {
        label: strings.description,
        key: "description",
      },
      {
        label: strings.unit,
        key: "Unit",
      },
      {
        label: strings.productImage,
        key: "picture",
      },
      {
        label: "Category",
        key: "category",
      },
      {
        label: strings.taxed,
        key: "tax",
      },
      {
        label: strings.taxRate,
        key: "tax_rate",
      },
      {
        label: strings.taxType,
        key: "tax_type",
      },
      {
        label: strings.trackInventory,
        key: "track_inventory",
      },
      {
        label: strings.hasVariants,
        key: `variants.length > 0 ? "True" : "False"`,
      },
      {
        label: strings.variants,
        key: "orderedVariants",
      },
      {
        label: "SKU",
        key: "sku",
      },
      {
        label: "product_sku",
        key: "product_sku",
      },
      {
        label: strings.createdAt,
        key: "created_at",
      },
    ];

    // const isAdmin = user.role_id === 1;
    // const isManager = user.role && user.role.id === 2;
    // const isCashier = user.role_id !== 1 && (user.role && user.role.id !== 2);

   
    const items = [
      {
        key: '1',
        label: (
          <><Button
            style={{ width: "100%" }}
            onClick={this.toggleImportProductModal}
          >
            {strings.importProduct}
          </Button></>
        ),
      },
      {
        key: '2',
        label: (
          <><Button
            className="btn-menu-wrapper"
            onClick={() => {
              notification.info({
                message: strings.exportProducts,
                description: strings.yourDownloadWillStart,
                duration: 5,
              });
            }}
            style={{ width: "100%" }}
            disabled={allProducts.length === 0}
          >
            <CSVLink
              data={exportableProductList}
              headers={acceptedHeaders}
              filename={"productsList.csv"}
              target="_blank"
            >
              {strings.exportProducts}
            </CSVLink>
          </Button></>
        ),
      },
      {
        key: '3',
        label: (
          <><Button
            style={{ width: "100%" }}
            onClick={() => this.setState({ addProductSku: true })}
          >
            Add Product SKU
          </Button></>
        ),
      }
    ]

    const bulkItem = [
      {
      key: 1,
      label: (
        <Button onClick={this.toggleBulkProductEditModal}>
          {strings.bulkPriceUpdate}
          {/* <Tag className="ml-1" color="green">
            {strings.new}
          </Tag> */}
        </Button>
      ),
    },
    {
      key: 2,
      label: (
        <Button onClick={this.toggleBulkQuantityEditModal}>
          {strings.bulkEditStock}
          {/* <Tag className="ml-1" color="green">
            {strings.new}
          </Tag> */}
        </Button>
      ),
    }
  ]

    const AddProductSkuForm = ({ form, onCancel, visible }) => {
      const { getFieldDecorator } = form;

      const handleAddProduct = () => {
        form.validateFields((err, values) => {
          if (err) return;
          return this.setState({
            isAddProductModalVisible: true,
            addProductSku: false,
            product_sku: values.product_sku,
          });
        });
      };
      return (
        <Modal
          title="Add Product SKU"
          open={visible}
          onCancel={onCancel}
          footer={[
            <Button onClick={handleAddProduct} type="primary">
              Add Product
            </Button>,
            <Button onClick={onCancel}>Cancel</Button>,
          ]}
        >
          <Form>
            <Form.Item>
              {getFieldDecorator("product_sku", {
                rules: [
                  {
                    required: true,
                    message: "Please enter product SKU to continue",
                  },
                ],
              })(<Input placeholder="Enter Product SKU" autoFocus />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    };

    const AddProductSku = Form.create()(AddProductSkuForm);

    return (
      <div className="page-container" id="products">
        {/* Main Content */}
        <main>
          <Card
            title={`${strings.products} ${
              myBranch ? `- ${myBranch.name}` : ""
            }`}
            extra={
              <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                <Button type="primary" onClick={() => this.setState({ openShopLinkModal: true })}>Shop Link</Button>
                <Dropdown  menu={{ items }}>
                  {/* <> */}
                    <Button
                      icon={<PlusOutlined />}
                      size="default"
                      onClick={this.toggleAddProductModal}
                      type="primary"
                    >
                      {strings.addNewProduct} <Icon type="down" />
                    </Button>
                  {/* </> */}
                </Dropdown>
              </div>
            }
          >
            {this.state.addProductSku && (
              <AddProductSku
                onCancel={() => this.setState({ addProductSku: false })}
                visible={this.state.addProductSku}
              />
            )}
            <section className="subheader-btn">
              <div>
                <div>
                  <Button
                    className="btn-first"
                    onClick={this.toggleViewCategoryModal}
                  >
                    {strings.viewCategory}
                  </Button>

                  <Button
                    onClick={this.toggleViewSuppliersModal}
                    loading={this.state.isSuppliersLoading}
                  >
                    {strings.viewSuppliers}{" "}
                    {/* <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag> */}
                  </Button>

                  <Button onClick={this.toggleAddProductSuppliesModal}>
                    {strings.receiveInventory}{" "}
                    {/* <Tag className="ml-1" color="green"> */}
                      {/* {strings.new} */}
                    {/* </Tag> */}
                  </Button>

                  <div style={{ display: "inline-flex" }}>
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          className="btn-menu-wrapper"
                          style={{ width: "100%" }}
                        >
                          {strings.printAllProductsTag}
                        </Button>
                      )}
                      content={() => this.componentRef}
                    />
                    <div style={{ display: "none" }}>
                      <ProductsBarCode
                        products={products}
                        ref={(el) => (this.componentRef = el)}
                        user={user}
                      />
                    </div>
                  </div>

                  <Button onClick={this.sendBulkInventory}>
                    {strings.sendBulkInvent}
                    {/* <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag> */}
                  </Button>

                  {isProductBundleActive && 
                  <Button onClick={this.addBundleProduct}>
                    {strings.productBundle}
                    {/* <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag> */}
                  </Button>}

                  {isNitroActive && <Button onClick={this.openThePriceChecker}>
                    {strings.priceChecker}
                    {/* <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag> */}
                  </Button>}

                  {isAdmin && hasSelected && (
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={this.toggleBulkDeleteModal}
                    >
                      {strings.deleteAll}
                    </Button>
                  )}

                  {hasSelected && <Dropdown menu={{ items: bulkItem }}>
                    <Button
                      // type="primary"
                    >
                      {strings.BulkEdit} <Icon type="down" />
                      {/* <Tag className="ml-1" color="green">
                        {strings.new} 
                      </Tag> */}
                    </Button>
                  </Dropdown>}

                  {selectOne &&
                    <Button onClick={this.handleCloneProduct}>
                      {strings.cloneProduct}
                      <Tag className="ml-1" color="green">
                        {strings.new}
                      </Tag>
                    </Button>
                  }

                  <Button
                    onClick={() => this.setState({ isShowReturnModal: true })}
                  >
                    {strings.viewReturnedItems}{" "}
                    <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag>
                  </Button>


                  <Button
                    onClick={() => this.setState({ isShowDamagedModal: true })}
                  >
                    {strings.viewDamagedItems}{" "}
                    <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag>
                  </Button>

                  {isPurchaseOrderActive && <Button
                    onClick={() => this.setState({ createPurchaseOrder: true })}
                  >
                    {strings.CreatePurchaseOrder}{" "}
                    <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag>
                  </Button>}

                  {isPurchaseOrderActive && <Button
                    onClick={() => this.setState({ acceptPurchaseOrder: true })}
                  >
                    {strings.acceptOrder}{" "}
                    <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag>
                  </Button>}

                  {/* <Button
                    onClick={() => this.setState({ isProductHistoryModalVisible: true })}
                  >
                    {strings.viewProductHistory}{" "}
                    <Tag className="ml-1" color="green">
                      {strings.new}
                    </Tag>
                  </Button> */}
                </div>
              </div>
            </section>

            <section className="products-table mt-30">
              <div style={{ display: "flex" }}>
                <span style={{ marginRight: "10px" }}>
                  {strings.totalProducts}:{" "}
                  {this.state.total_products === 0
                    ? sortedProducts && sortedProducts.length
                    : this.state.total_products}
                </span>
                <span style={{
                  marginLeft: "0.5rem"
                }}>INVENTORY VALUE: </span>
                <div 
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: shouldShowCpandSp && !isProductCpAndSploading ? "0 0.1rem" : "0 0.5rem"
                  }}
                >
                  {isProductCpAndSploading ? 
                    <Spin onClick={()=> {
                      this.props.match.params?.branchId ? this.getBranchInventoryValues() :this.getProductCpandSp()}}/> :
                      <ReloadOutlined 
                        onClick={()=>{
                          if(shouldShowCpandSp){
                            this.setState({ shouldShowCpandSp: false})
                          }else{
                            this.props.match.params?.branchId ? this.getBranchInventoryValues() :this.getProductCpandSp();
                            this.setState({ shouldShowCpandSp: true })
                          }
                        }}
                        
                        style={{
                          display: shouldShowCpandSp && !isProductCpAndSploading ? "none" : "unset"
                        }}
                      />
                  }
                </div>
          
                {isAdmin && shouldShowCpandSp && !isProductCpAndSploading && (
                  this.props.match.params?.branchId ? <span style={{ marginLeft: "10px" }}>
                  {`
                     ${strings.inventoryValue}: ${
                    user.currency ? user.currency : "NGN"
                  }`}{`${numberFormatter(inventoryValue || 0)}
                  `}
                </span>   :<><span style={{ marginRight: "10px" }}>
                    {`
                       ${strings.totalCostPrice}: ${
                      user.currency ? user.currency : "NGN"
                    }`}{`${numberFormatter(this.state.total_cost_price || 0)}
                    `}
                  </span> 
                  <span style={{margin: "0 0.5rem 0 0"}}>||</span> 
                  <span>
                  {`
                   ${strings.totalSellingPrice}: ${
                    user.currency ? user.currency : "NGN"
                  }`}{`${numberFormatter(this.state.total_selling_price || 0)}
                `}
                </span></>
                )}

                <div 
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 0.5rem",
                    display: shouldShowCpandSp && !isProductCpAndSploading ? "unset" : "none"
                  }}
                >
                  <ReloadOutlined 
                    onClick={()=>{
                      if(shouldShowCpandSp){
                        this.setState({ shouldShowCpandSp: false})
                      }else{
                        this.getProductCpandSp();
                        this.setState({ shouldShowCpandSp: true })
                      }
                    }}
                  />
                  
                </div>

                {/* {isAdmin && (
                  <span>
                    {`
                     ${strings.totalSellingPrice}: ${
                      user.currency ? user.currency : "NGN"
                    }`}{isProductCpAndSploading ? <Spin /> : `${numberFormatter(this.state.total_selling_price || 0)}
                  `}
                  </span>
                )} */}
              </div>

              <CustomDataTable
                columns={columns}
                dataSource={sortedProducts && sortedProducts.map((product) => {
                  return {
                    ...product,
                    key: product.id,
                  };
                })}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      this.toggleViewProductModal(record);
                    },
                  };
                }}
                loading={loadingProducts}
                rowSelection={rowSelection}
                onChange={this.onChangeTablePage}
                pagination={this.state.pagination}
              />
            </section>
          </Card>

          {/* Add Product Modal */}
          {isAddProductModalVisible ? (
            <AddProductModal
              showModal={isAddProductModalVisible}
              closeModal={this.toggleAddProductModal}
              updateProducts={() => {
                this.getAllProducts();
                !isNaN(branchId)
                  ? this.fetchAllBranchProducts()
                  : this.fetchProducts();
              }}
              toggleAddLoyaltyModal={this.handleToggleLoyaltyProgramModal}
              user={user}
              product_sku={this.state.product_sku}
              uRewardID={this.state.uRewardID}
              total_products={this.state.total_products}
              suppliers={suppliers}
              isPurchaseOrderActive={isPurchaseOrderActive}
            />
          ) : null}
          {/* //Add Product Modal */}

          {isEditProductModalVisible ? (
            <EditProductModal
              user={user}
              product={currentActionRecord}
              showModal={isEditProductModalVisible}
              closeModal={this.toggleEditProductModal}
              updateProducts={() => {
                this.getAllProducts();
                !isNaN(branchId)
                  ? this.fetchAllBranchProducts()
                  : this.fetchProducts();
              }}
              closeViewProductModal={this.toggleViewProductModal}
              branchId={branchId}
              uRewardID={this.state.uRewardID}
              suppliers={suppliers}
              isPurchaseOrderActive={isPurchaseOrderActive}
            />
          ) : null}

          {isCloneProductModalOpen ? (
            <CloneProductModal 
              showModal={isCloneProductModalOpen}
              closeModal={()=>{
                this.setState({ 
                  isCloneProductModalOpen: false,
                  productToClone: null,
                  currentActionRecord: null
                })
              }}
              product={productToClone}
              updateProducts={() => {
                this.getAllProducts();
                !isNaN(branchId)
                  ? this.fetchAllBranchProducts()
                  : this.fetchProducts();
              }}
              toggleAddLoyaltyModal={this.handleToggleLoyaltyProgramModal}
              user={user}
              product_sku={this.state.product_sku}
            />
          ) : null}

          {isDeleteModalVisible ? (
            <DeleteProductModal
              product={currentActionRecord}
              showModal={isDeleteModalVisible}
              closeModal={this.toggleDeleteProductModal}
              updateProducts={() => {
                this.getAllProducts();
                !isNaN(branchId)
                  ? this.fetchAllBranchProducts()
                  : this.fetchProducts();
              }}
              closeViewDetailsModal={this.toggleViewProductModal}
              branchId={branchId}
              user={user}
            />
          ) : null}

          { this.state.isShowReturnModal && (
            <ReturnedItems 
              open={this.state.isShowReturnModal}
              onCancel={() => this.setState({ isShowReturnModal: false })}
            />
          )}


          {this.state.isShowDamagedModal && (
            <DamagedItems 
              open={this.state.isShowDamagedModal}
              onCancel={() => this.setState({ isShowDamagedModal: false })}
            />
          )}

          {isBulkDeleteModalVisible ? (
            <BulkDeleteProductModal
              productIds={selectedRowKeys}
              showModal={isBulkDeleteModalVisible}
              closeModal={this.toggleBulkDeleteModal}
              updateProducts={() => {
                this.getAllProducts();
                !isNaN(branchId)
                  ? this.fetchAllBranchProducts()
                  : this.fetchProducts();
              }}
              branchId={branchId}
              user={user}
            />
          ) : null}

          {isBulkSendInventoryModal && (
            <BulkSendInventoryModal
              productIds={selectedRowKeys}
              showModal={isBulkSendInventoryModal}
              products={products}
              closeModal={() =>
                this.setState({ isBulkSendInventoryModal: false })
              }
              cancelSelection={() => this.setState({ selectedRowKeys: [] })}
              updateProducts={() => {
                this.getAllProducts();
                !isNaN(branchId)
                  ? this.fetchAllBranchProducts()
                  : this.fetchProducts();
              }}
            />
          )}

          {isSendInventoryVisible ? (
            <SendInventoryModal
              product={currentActionRecord}
              showModal={isSendInventoryVisible}
              closeModal={this.toggleSendInventoryModal}
              updateProducts={() => {
                this.getAllProducts();
                this.fetchProducts();
              }}
            />
          ) : null}

          {isUpdateInventoryVisible ? (
            <UpdateInventoryModal
              product={currentActionRecord}
              showModal={isUpdateInventoryVisible}
              closeModal={this.toggleUpdateInventoryModal}
              updateProducts={() => {
                this.getAllProducts();
                this.fetchProducts();
              }}
            />
          ) : null}

          {isAddCategoryVisible ? (
            <AddCategoryModal
              showAddCategoryModal={isAddCategoryVisible}
              toggleModal={this.toggleAddCategoryModal}
            />
          ) : null}

          {isViewProductVisible ? (
            <ViewDetailsModal
              currentProduct={currentActionRecord}
              showModal={isViewProductVisible}
              closeModal={this.toggleViewProductModal}
              categories={allCategories}
              toggleEditProductModal={this.toggleEditProductModal}
              toggleDeleteProductModal={this.toggleDeleteProductModal}
              toggleSendInventoryModal={this.toggleSendInventoryModal}
              toggleUpdateInventoryModal={this.toggleUpdateInventoryModal}
              toggleAddProductSuppliesModal={this.toggleAddProductSuppliesModal}
              getProductHistory={getProductHistory}
              getSingleProductHistory={getSingleProductHistory}
              user={user}
              branchId={branchId}
              updateProducts={() => {
                this.getAllProducts();
                !isNaN(branchId)
                  ? this.fetchAllBranchProducts()
                  : this.fetchProducts();
              }}
              isPurchaseOrderActive={isPurchaseOrderActive}
            />
          ) : null}

          {isViewCategoryVisible ? (
            <ViewCategoryModal
              isViewCategoryVisible={isViewCategoryVisible}
              closeModal={this.toggleViewCategoryModal}
              toggleAddCategoryModal={this.toggleAddCategoryModal}
              toggleEditCategoryModal={this.toggleEditCategoryModal}
              toggleDeleteCategoryModal={this.toggleDeleteCategoryModal}
            />
          ) : null}

          {isEditCategoryModalOpen ? (
            <EditCategoryModal
              isEditCategoryModalOpen={isEditCategoryModalOpen}
              closeModal={this.toggleEditCategoryModal}
              currCategory={this.state.currCategory}
              toggleCategoryModal={this.toggleViewCategoryModal}
            />
          ) : null}

          {isDeleteCategoryModalOpen ? (
            <DeleteCategoryModal
              isDeleteCategoryModalOpen={isDeleteCategoryModalOpen}
              closeModal={this.toggleDeleteCategoryModal}
              currCategory={this.state.currCategory}
              toggleCategoryModal={this.toggleViewCategoryModal}
            />
          ) : null}

          {isAddLoyaltyProgramModalVisible ? (
            <AddLoyaltyProgram
              visible={isAddLoyaltyProgramModalVisible}
              toggleModal={this.handleToggleLoyaltyProgramModal}
              getAllLoyaltyPrograms={getAllLoyaltyPrograms}
            />
          ) : null}

          {isImportModalVisible ? (
            <ImportProductModal
              toggleImportProductModal={this.toggleImportProductModal}
              isImportProductModalVisible={isImportModalVisible}
              importType={importType}
              productList={this.props.products}
              user={this.props.auth.user}
              setImportType={this.setImportType}
              total_products={this.state.total_products}
            />
          ) : null}

          {isViewSuppliersModalVisible ? (
            <ViewSuppliersModal
              visible={isViewSuppliersModalVisible}
              closeModal={this.toggleViewSuppliersModal}
              toggleAddSupplierModal={this.toggleAddSupplierModal}
            />
          ) : null}

          {isProductHistoryModalVisible ? (
            <ProductHistory
              open={isProductHistoryModalVisible}
              onCancel={() => this.setState({ isProductHistoryModalVisible: false })}
              getProductHistory={getProductHistory}
              // toggleAddSupplierModal={this.toggleAddSupplierModal}
            />
          ) : null}

          {isAddSuppliersModalVisible ? (
            <AddSupplierModal
              visible={isAddSuppliersModalVisible}
              closeModal={this.toggleAddSupplierModal}
            />
          ) : null}

          {isAddProductSuppliesModalVisible ? (
            <AddProductSupplies
              visible={isAddProductSuppliesModalVisible}
              closeModal={this.toggleAddProductSuppliesModal}
              currentProduct={currentActionRecord}
              updateProducts={() => {
                this.getAllProducts();
                !isNaN(branchId)
                  ? this.fetchAllBranchProducts()
                  : this.fetchProducts();
              }}
            />
          ) : null}

          {isNitroActive && openPriceChecker && 
            <PriceChecker 
              open={openPriceChecker}
              onCancel={()=>this.setState({ openPriceChecker: false })}
            />
          }
          { addBundleModal && 
            <AddBundleModalForm 
              open={addBundleModal}
              onCancel={()=>this.setState({ addBundleModal: false })}
              user={user}
            />
          }
          {
            showBulkProductEdit && 
              <BulkUpdateModal 
                open={showBulkProductEdit}
                onCancel={this.toggleBulkProductEditModal}
                selectedRowKeys={selectedRowKeys}
                products={products}
                updateProducts={() => {
                  this.getAllProducts();
                  !isNaN(branchId)
                    ? this.fetchAllBranchProducts()
                    : this.fetchProducts();
                }}
              />
          }

          {
            showBulkQuantityEdit && 
              <BulkQuantityEdit 
                open={showBulkQuantityEdit}
                onCancel={this.toggleBulkQuantityEditModal}
                selectedRowKeys={selectedRowKeys}
                products={products}
                cancelSelection={() => this.setState({ selectedRowKeys: [] })}
                updateProducts={() => {
                  this.getAllProducts();
                  !isNaN(branchId)
                    ? this.fetchAllBranchProducts()
                    : this.fetchProducts();
                }}
              />
          }
          {this.state.openShopLinkModal && (
            <ShopLinkModal 
              open={this.state.openShopLinkModal}
              onCancel={() => this.setState({ openShopLinkModal: false })}
              user={this.props.auth.user}
            />
          )}
          {createPurchaseOrder && (
            <CreatePurchaseOrder 
              open={createPurchaseOrder}
              onCancel={() => this.setState({ createPurchaseOrder: false })}
              user={user}
            />
          )}

          {acceptPurchaseOrder && (
            <AcceptPurchaseOrder 
              open={acceptPurchaseOrder}
              onCancel={() => this.setState({ acceptPurchaseOrder: false })}
              user={user}
              allProducts={this.props.allProducts}
            />
          )}
        </main>
        {/* //Main Content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  products: state.product.pageProducts,
  branchProducts: state.product.branchProducts || [],
  allCategories: state.category.allCategories,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  error: state.error,
  branches: state.branch.allBranches,
  isNitroActive: state.appStore.isNitroActive,
  isPurchaseOrderActive: state.appStore.isPurchaseOrderActive,
  isProductBundleActive: state.appStore.isProductBundleActive,
  allProducts: state.product.allProducts,
  suppliers: state.product.suppliers,
});

Products.propTypes = {
  auth: PropTypes.object.isRequired,
  allCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  allLoyaltyPrograms: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAllCategories: PropTypes.func.isRequired,
  getPageProducts: PropTypes.func.isRequired,
  addNewBusinessBranch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getPageProducts,
  getAllProducts,
  addNewBusinessBranch,
  getAllCategories,
  getAllLoyaltyPrograms,
  getAllBusinessBranches,
  getAllBranchProducts,
  getSuppliers,
  getProductsCpAndSp,
  getBranchInventory,
  getProductsByCategory,
  getProductHistory,
  getSingleProductHistory
})(WithApolloClient(Products));
