import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  InputNumber,
  Popover,
  message,
  Select,
  Badge,
  Tooltip,
  DatePicker,
  Checkbox,
  Image
} from "antd";
import { Icon } from "@ant-design/compatible";
import { connect } from "react-redux";
import { getAllBusinessBranches } from "../../../../redux/actions/branchActions";
import { Link, useHistory } from "react-router-dom";
import numberFormatter from "../../../../utils/numberFormatter";
import InvoiceForm from "../../../../components/Invoice/InvoiceForm";
import BankTransferModal from "./BankTransferModal";
import VpayTransfer from "./VpayTransfer";
import PropTypes from "prop-types";
import api from "../../../../api";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import VerifyTokenModal from "../VerifyTokenModal";
import { getTill, getCashierTill } from "../../../Dashboard/Layouts/Account/functions";
import PaystackTransfer from "./PaystackTransfer";
import { credPalLogo } from "../../../../icons";
import CredpalProcessModal from "./credpalModal";
// UI
import "./index.scss";
import {
  disabledCardButtonEmails,
  disabledInvoiceButtonEmails,
  disabledBankTransferButtonEmails,
  disabledCashButtonEmails,
  disabledPointPaymentEmails,
} from "./emails";
import { ADD_ORDERS, GET_INSTORE_ORDERS } from "../../../../constants";
import SaveDraftModal from "./SaveDraftModal";
import Discount from "./Discount";
import strings from "../../../../strings";
import {
  EDIT_GIFT_CARD,
  LIST_GIFT_CARDS,
  CONFIRM_ORGANISATION_GIFTCARD,
  USE_ORGANISATION_GIFTCARD
} from "../../../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";
import {
  RedoOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import PartnerGiftcardModal from "./partnerGiftcard";
import PaystackPaymentModal from "./paystack";
import { activeSplitPayment } from "../../../../redux/actions/saleActions";

const RecordSalePaymentModal = ({
  user,
  location,
  visible: isRecordSalePaymentVisible,
  toggleTransactionModal,
  onCancel,
  onCashValueChange,
  customerCash,
  getCartTotalAmount,
  recordingCardSale,
  recordingGiftCardSale,
  recordingCashSale,
  recordingManualTransfer,
  cashChange,
  showCashForm,
  handlePayment,
  toggleShowCashForm,
  toggleShowInvoicePrompt,
  invoicePrompt,
  selectedCustomer,
  customerValue,
  customer,
  customers,
  onCustomerSearch,
  cleanupSelectCustomerDropdown,
  cartList,
  addProductToCart,
  removeItemFromCart,
  reduceItemFromCart,
  handleShowBulkQuantityModal,
  handleSaveInvoiceDone,
  clearCart,
  clearSelectedCustomer,
  allLoyaltyPrograms,
  handlePaymentWithPoint,
  isSubscribedToMultiLevel,
  isPayingWithPoints,
  isTwoPaymentMethod,
  loyalty_id,
  mtier_amount,
  getAllBusinessBranches,
  allBranches,
  cardId,
  clearTopupGiftCard,
  topupGiftCard,
  categories,
  isDisableNewFreePlanAccounts,
  isNitroActive,
  fetchActiveApps,
  checkoutData,
  isKitchenScreenActive,
  isPaystackTerminalActive,
  splitPaymentActive,
  activeSplitPayment,
  isCredPalActive,
  handleBlurFactor
}) => {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [isVpayModalOpen, setIsVpayModalOpen] = useState(false);
  const [selectVpayUpayModalOpen, setSelectVpayUpayModalOpen] = useState(false);
  const [selectCardTypeModalOpen, setSelectCardTypeModalOpen] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [discountValue, setDiscountValue] = useState(null);
  const [customDiscountValue, setCustomDiscountValue] = useState(null);
  const [discountType, setDiscountType] = useState("");
  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isCustomDiscountEnabled, setIsCustomDiscountEnabled] = useState(false);
  const [isTransferRouteActive, setIsTransferRouteActive] = useState(false);
  const [isSaveDraftModalOpen, setIsSaveDraftModalOpen] = useState(false);
  const [verifyVisible, setVerifyVisible] = useState(false);
  const [verifyModalContent, setVerifyModalContent] = useState("");
  const [verifyFunction, setVerifyFunction] = useState("");
  const [getOtpLoading, setGetOtpLoading] = useState(false);
  const [branchesAccountNumber, setBranchesAccountNumber] = useState({});
  const [loading, setLoading] = useState(false);
  const [branchNuban, setBranchNuban] = useState(null);
  const [branchQr, setBranchQr] = useState(null);
  const [bankNubanName, setBankNubanName ] = useState(null)
  const [selectGiftCard, setSelectGiftCard] = useState(false);
  const [terminalData, setTerminalData] = useState([]);
  const [getTerminalDataLoading, setGetTerminalDataLoading] = useState(false);
  const [paystackInvoiceData, setPaystackInvoiceData] = useState(null);
  const [openAwaitPaystackPaymentModal, setOpenAwaitPaystackPaymentModal] =
    useState(false);
  const [resendInvoiceLoading, setResendInvoiceLoading] = useState(false);
  const [currentTerminalId, setCurrentTerminalId] = useState(null);
  const [transactionDate, setTransactionDate] = useState(null);
  const [cancelModal, setCancelModal] = useState(false);
  const [ partnerGiftCard, setPartnerGiftcard ] = useState(false);
  const [ cashierTill, setCashierTill ] = useState({});
  const [ isCashier, setIsCashier ] = useState(false);
  const [ totalOrder, setTotalOrder ] = useState([]);
  const [ openPaystackPaymentModal, setOpenPaystackPaymentModal ] = useState(false);
  const [ payStackDetails, setPayStackDetails ] = useState(null);
  const [ fetchLoading, setFetchLoading ] = useState(false);
  const [ splitPaymentMethods, setSplitPaymentMethods ] = useState({
    method1: {
      method: null,
      amount: null,
    },
    method2:  {
      method: null,
      amount: null,
    }
  });
  const [ amountRemaining, setAmountRemaining ] = useState(null);
  const [ totalAmount, setTotalAmount ] = useState(0);
  const [ proceedPayment, setProceedPayment ] = useState(false);
  const [ openCredPalModal, setOpenCredPalModal ] = useState(false);
  

  const isAdmin = user.role_id === 1;
  const isAdminIsManager =
  user.role_id === 1 || (user.role && user.role.id === 2);

  const history = useHistory();
  const homeBranch = localStorage.getItem("homebranch") ? JSON.parse(localStorage.getItem("homebranch")) : {id: null};

  const { data: listCards, loading: listCardLoading } =
    useQuery(LIST_GIFT_CARDS);

    const { 
      data: inStoreData, 
      refetch, 
      loading: instoreLoading
    } = useQuery(
      GET_INSTORE_ORDERS, 
      {variables: 
        { 
          merchant_id: user?.business_branch?.home === false 
            ? user?.employer?.id  
              || user.id : user?.employer?.id  || user.id,
          branch_id: user?.business_branch 
            ? user?.business_branch.id 
              : homeBranch.id, homebranch_id: !user?.business_branch 
                ? homeBranch.id : null 
        },
        onCompleted: (data) => {
          // console.log(data.getInstoreOrders.length, cartList)
          setTotalOrder(data.getInstoreOrders.length);
        }
    });

  const [editCard, { loading: editCardLoading }] = useMutation(EDIT_GIFT_CARD, {
    onCompleted: (data) => {
      if (data.editGiftCard) {
        if(splitPaymentActive){
          if(splitPaymentMethods && splitPaymentMethods.method1.method === null){
            setSplitPaymentMethods(prev => {
              return {
                ...prev,
                method1: {
                  method: "gift_card",
                  amount: Number(amountRemaining).toFixed(2)
                }
              }
            });
            return;
          }else if (splitPaymentMethods && splitPaymentMethods.method2 === null){
            setSplitPaymentMethods(prev => {
              return {
                ...prev,
                method2: {
                  method: "gift_card",
                  amount: Number(totalAmount).toFixed(2)
                }
              }
            });
    
            if (hasDiscount || hasInstantRewardDiscount()) {
              handlePayment(
                `${splitPaymentMethods.method1.method} + gift_card`,
                location,
                true,
                discountAmount || instantRewardDiscount,
                `${data.editGiftCard.card_number}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:gift_card:${totalAmount}`,
                clearDiscount,
                transactionDate || Date.now(),
                new Date(transactionDate || Date.now()).getTime(),
              );
            } else {
              handlePayment(
                `${splitPaymentMethods.method1.method} + gift_card`,
                location,
                false,
                null,
                `${data.editGiftCard.card_number}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:gift_card:${totalAmount.toFixed(2)}`,
                clearDiscount,
                transactionDate || Date.now(),
                new Date(transactionDate || Date.now()).getTime(),
              );
            }
          }
        }else{
          if (hasDiscount || hasInstantRewardDiscount()) {
            handlePayment(
              "gift_card",
              location,
              true,
              discountAmount || instantRewardDiscount,
              data.editGiftCard.card_number,
              clearDiscount,
              transactionDate || Date.now(),
              new Date(transactionDate || Date.now()).getTime(),
            );
          } else {
            handlePayment(
              "gift_card",
              location,
              false,
              null,
              data.editGiftCard.card_number,
              clearDiscount,
              transactionDate || Date.now(),
              new Date(transactionDate || Date.now()).getTime(),
            );
          }
        }
      }
    },
    onError: (error) => {
      message.error(error.message, 5);
    },
    refetchQueries: [LIST_GIFT_CARDS],
  });

  const [addOrders, { error, loading: addOrderLoading }] = useMutation(ADD_ORDERS, {
    onCompleted: (data) => {
      Modal.success({
        title: "Item(s) sent to kitchen display screen",
        onOk: () => {
          toggleShowCashForm(false, "modal-cancel");
          clearTopupGiftCard();
          onCancel();
          setHasDiscount(false);
          // handleSelectDiscount(0);
          setCustomDiscountValue("");
          clearCart();
        }
      })
    },
    onError: (err) => {
      console.log(err)
      Modal.error({
        title: "Unable to send to kitchen screen, please try again!"
      })
    }
  });

  //all gift cards assigned to customers
  const giftCardCustomers = {};
  !listCardLoading &&
    listCards?.listGiftCards.length > 0 &&
    listCards?.listGiftCards.forEach((card) => {
      if (card.customer_id) {
        if (giftCardCustomers[card.customer_id]) {
          giftCardCustomers[card.customer_id].push(card);
        } else giftCardCustomers[card.customer_id] = [card];
      }
    });

  useEffect(async ()=>{
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    const res = await fetchActiveApps();
    const check = res.data;
    let newApps = [];

    await check.map((appCont)=>{
      newApps.push(appCont.app);
    });

    if(newApps.length !== 0){
      const isVpayPresent = newApps.filter((app)=>{
        return app.app_name.toLowerCase().includes("vpay");
      });

      if(user.employer){
        if(isVpayPresent.length !== 0){    
          getCashierNubanTill(user.id)
        }
      }
    }
  }, [user]);

  useEffect(()=>{
    isAdmin && isPaystackTerminalActive && getPayStackNuban();
    handleBlurFactor(false);
  }, [])

  const totalAmountToBePaid =
    hasDiscount && !topupGiftCard && discountAmount
      ? (getCartTotalAmount() - discountAmount).toFixed(2)
      : isTwoPaymentMethod
      ? getCartTotalAmount() - mtier_amount
      : getCartTotalAmount();

  const getCashierNubanTill = ( id ) => {
    getCashierTill(id)
    .then((res)=>{
      if(res.status === 200){
        setCashierTill(res.data);
        setIsCashier(true);
      }
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  const handleGetTill = (id) => {
    setLoading(true);
    getTill(id)
      .then((res) => {
        setLoading(false);
        setBranchesAccountNumber((prev) => ({
          ...prev,
          [id]: res.data,
        }));
      })
      .catch((err) => {
        setLoading(false);
        console.log({ getTillError: err });
      });
  };

  const getPaystackTerminal = (branch_id) => {
    setGetTerminalDataLoading(true);
    api.HttpClient.get(`/merchants/get_terminal/${branch_id}`)
      .then((res) => {
        setGetTerminalDataLoading(false);
        if (res.status === 200 && res.data) {
          setTerminalData((prev) => [...prev, res.data]);
        }
      })
      .catch((err) => {
        setGetTerminalDataLoading(false);
        message.error(err.response?.data.message || err.message);
      });
  };

  const initiatePaystackInvoice = (terminal_id) => {
    setCurrentTerminalId(terminal_id);
    const branch_id = user.business
      ? user.business_branch.id
      : allBranches.find((branch) => branch.name?.toLowerCase().match("home"))
          ?.id;
    const payload = {
      data: {
        customer_id: customer?.id,
        branch_id,
        amount:
          (hasInstantRewardDiscount()
            ? Number(totalAmountToBePaid - instantRewardDiscount).toFixed(2)
            : Number(totalAmountToBePaid).toFixed(2)) * 100,
        timeStamp: Date.now(),
      },
    };
    setGetTerminalDataLoading(true);
    api.HttpClient.post("/merchants/initiate_paystack_invoice", payload)
      .then((res) => {
        setGetTerminalDataLoading(false);
        if (res.status === 200) {
          setPaystackInvoiceData({ ...res.data, terminal_id });
          setOpenAwaitPaystackPaymentModal(true);
        }
      })
      .catch((err) => {
        setGetTerminalDataLoading(false);
        message.error(err.response?.data.message || err.message);
      });
  };

  const resendPaystackInvoice = () => {
    const { paystack_invoice_id, offline_reference, terminal_id } =
      paystackInvoiceData;
    const payload = {
      data: {
        paystack_invoice_id,
        offline_reference,
        terminal_id,
      },
    };

    setResendInvoiceLoading(true);
    api.HttpClient.post("/merchants/resend_paystack_invoice", payload)
      .then((res) => {
        setResendInvoiceLoading(false);
        if (res.status === 200) {
          message.success("Bill sent to Terminal");
          setCurrentTerminalId(terminal_id);
          setOpenAwaitPaystackPaymentModal(true);
        }
      })
      .catch((err) => {
        setResendInvoiceLoading(false);
        message.error(err.response?.data.message || err.message);
      });
  };

  useEffect(() => {
    getAllBusinessBranches();
  }, []);

  useEffect(() => {
    const branch_id = user.business_branch
      ? [user.business_branch.id]
      : allBranches.map((branch) => branch.id);

    branch_id.forEach((id) => getPaystackTerminal(id));
  }, []);

  useEffect(() => {
    allBranches.length > 0 &&
      allBranches.forEach((branch) => handleGetTill(branch.id));
  }, []);

  const activeInstantRewardLoyalty = JSON.parse(
    localStorage.getItem("activeInstantRewardLoyalty")
  );

  const toggleBankTransferModal = (value) => {
    setIsTransferModalOpen(value);
  };

  const setPaymentMethod = (paymentMethod) => {
    setPaymentType(paymentMethod);
  };

  const verifyOTP = async (func) => {
    if (!customer) return func();
    const phoneNumber = customer.phone_number;
    if (
      phoneNumber &&
      phoneNumber.length < 11 &&
      activeInstantRewardLoyalty?.verify_otp
    ) {
      return message.error(
        `Customer has no valid phone number to get OTP to redeem ${activeInstantRewardLoyalty?.name} reward!`
      );
    } else if (phoneNumber && activeInstantRewardLoyalty?.verify_otp) {
      setGetOtpLoading(true);
      message.info("Sending OTP to customer's phone");
      const res = await api.HttpClient.put(
        "/generate_phone_verification_token",
        {
          phone_number: phoneNumber,
        }
      );
      setGetOtpLoading(false);
      if (res.status === 200) {
        setVerifyFunction(() => func);
        setVerifyModalContent(
          <div>
            <p>Enter OTP sent to customer's phone to apply discount to sales</p>
          </div>
        );
        setVerifyVisible(true);
      } else return message.error("Error getting OTP");
      message.info(res.data.message);
    } else return func();
  };

  const handlePayWithManualTransfer = () => {
    // if(splitPaymentActive && amountRemaining === null) return message.error("Enter amount to be transferred");
    if(splitPaymentActive && splitPaymentMethods.method1.method === null && amountRemaining >= Number(totalAmount)) return message.error("Cannot use split payment on complete amount");
    // if(splitPaymentActive && !proceedPayment) return message.error("Please enter the remaining amount");

    Modal.confirm({
      title: `${strings.confirmPayment}. ${currency}${
        splitPaymentActive ? amountRemaining : hasInstantRewardDiscount()
          ? Number(Number(totalAmountToBePaid) - instantRewardDiscount).toFixed(
              2
            )
          : Number(totalAmountToBePaid).toFixed(2)
      }`,
      okText: "Ok",
      cancelText: strings.cancel,
      content: (
        <div>
          <div>{strings.hasPaymentBeenMade}</div>
          <div className="mt-2">
            <Input
              id="transfer-reference-input"
              type="text"
              className="mt-2"
              placeholder={strings.transactionReference}
            />
          </div>
        </div>
      ),
      onOk: handleTransferPayment,
    });
  };

  const getPayStackNuban = async () => {
    setFetchLoading(true);
    await api.merchants.getPayStackVirtualTerminal()
    .then((res)=>{
        setFetchLoading(false);
        if(res.status === 200){
            const name = user.username ? user.username : user.first_name;
            // console.log(res.data);
            if(res.data.length > 0){
              const selectedAccount = res.data.filter((accounts)=>accounts.name.toLowerCase().includes(name));
              selectedAccount.length > 0 
                  ? setPayStackDetails(selectedAccount[0])
                      : setPayStackDetails(res.data[0]);
            }
        }
    })
    .catch((err)=>{
        setFetchLoading(false);
        console.log("er",err?.response, err.message);
        // message.error("unable to fetch terminal details")
    })
};

  const handleAddToKitchScreen = () => {
    if (!customer) {
      message.error(strings.pleaseSelectACustomer, 5);
      return;
    }
    
    const totaaORder = cartList.reduce((prev, curr)=> {
      return prev + (Number(curr.amount))
    }, 0);

    const cartListOrder = [...cartList];

    let orderCartList = [];
    cartListOrder.map((eachList)=> {
      const {
        amount,
        branch_id,
        description,
        id,
        merchant_id,
        merchant_loyalty_program_id,
        merchant_product_category_id,
        name,
        picture,
        price,
        quantity,
        tax,
        tax_rate,
        tax_type,
        total_quantity,
        track_inventory,
        variants
      } = eachList;

      const varianto = [...variants];
      varianto.length > 0 && delete varianto[0].quantityLeft;
      const newListChange = {
        amount,
        branch_id: user?.business_branch ? user?.business_branch.id : homeBranch.id,
        description,
        id,
        merchant_id,
        merchant_loyalty_program_id,
        merchant_product_category_id,
        name,
        picture,
        price,
        quantity,
        tax,
        tax_rate,
        tax_type,
        total_quantity: totaaORder,
        track_inventory,
        variants: variants && varianto 
      }

      orderCartList.push(newListChange);
    });

    const orderDetails = {
      email: "",
      deliveryAddress: "",
      orderNotes: ""
    }

    let orderID;
    const id = `ud_is${Math.floor(Math.random() * 10000000000)}`;

    if(totalOrder < 10){
      orderID = "000" + (Number(totalOrder) + 1);
    }else if(totalOrder < 100){
        orderID = "00" + (Number(totalOrder) + 1);
    }else if(totalOrder < 1000){
        orderID = "0" + (Number(totalOrder) + 1);
    }else{
        orderID = "" + (Number(totalOrder) + 1);
    }

    const customerAddress = `${customer.address_line1 ? customer.address_line1 : ""} ${customer.address_line2 ? ", " + customer.address_line2 : ""} ${customer.state !== "" ? "," + customer.state : ""} ${customer.country !== "" ? ", " +  customer.country : ""}`;

    const order = {
      orderID: id,
      order_id: orderID,
      merchant_id: user.employer ? user.employer.id : user.id,
      branch_id: user?.business_branch ? user?.business_branch.id : homeBranch.id,
      merchant_phone_number: user.employer ? user.employer.contact_number : user.contact_number,
      merchant_email: user.employer ? user.employer.email : user.email,
      merchant_name: user.employer ? user.employer.business_name : user.business_name,
      orderNotes: orderDetails.orderNotes,
      customer: {
          user_id: customer.user_id,
          first_name: customer.first_name,
          last_name: customer.last_name,
          phone_number: customer.phone_number,
          email: customer.email,
          sex: customer.sex,
          address: customerAddress,
          delivery_address: customerAddress
      },
      order_items: orderCartList,
      created_at: new Date(),
      status: "pending",
      processed_by: user && user?.role_id === 1 ? "Admin" : user?.username,
      reference_code: "",
      order_type: "in-store",
      paid_at: new Date(),
      paid_time: new Date(),
      expiry_date: new Date(),
      total: totaaORder
    } 

    // console.log(order);

    addOrders({ variables: { data: order } });

  }

  const handleTransferPayment = () => {
    const transferReference = document.getElementById(
      "transfer-reference-input"
    ).value;
    if(splitPaymentActive){
      if(splitPaymentMethods && splitPaymentMethods.method1.method === null){
        setSplitPaymentMethods(prev => {
          return {
            ...prev,
            method1: {
              method: "m_transfer",
              amount: Number(amountRemaining).toFixed(2)
            }
          }
        });
        return setSelectVpayUpayModalOpen(false);
      }else if (splitPaymentMethods && splitPaymentMethods.method2.method === null){
        setSplitPaymentMethods(prev => {
          return {
            ...prev,
            method2: {
              method: "m_transfer",
              amount: Number(totalAmount).toFixed(2)
            }
          }
        });

        if (hasDiscount || hasInstantRewardDiscount()) {
          handlePayment(
            `${splitPaymentMethods.method1.method} + m_transfer`,
            location,
            true,
            discountAmount || instantRewardDiscount,
            `${transferReference}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:m_transfer:${totalAmount}`,
            clearDiscount,
            transactionDate || Date.now(),
            new Date(transactionDate || Date.now()).getTime(),
          );
        } else {
          handlePayment(
            `${splitPaymentMethods.method1.method} + m_transfer`,
            location,
            false,
            null,
            `${transferReference}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:m_transfer:${totalAmount}`,
            clearDiscount,
            transactionDate || Date.now(),
            new Date(transactionDate || Date.now()).getTime(),
          );
        }
      }
    }else{
      if (hasDiscount || hasInstantRewardDiscount()) {
        handlePayment(
          "m_transfer",
          location,
          true,
          discountAmount || instantRewardDiscount,
          transferReference,
          clearDiscount,
          transactionDate || Date.now(),
          new Date(transactionDate || Date.now()).getTime(),
        );
      } else {
        handlePayment(
          "m_transfer",
          location,
          false,
          null,
          transferReference,
          clearDiscount,
          transactionDate || Date.now(),
          new Date(transactionDate || Date.now()).getTime(),
        );
      }
    }
  };


  useEffect(()=>{
    setTotalAmount(totalAmount);
  }, [totalAmount]);

  useEffect(()=>{
    setAmountRemaining(amountRemaining);
  }, [amountRemaining])

  const handlePayWithCard = () => {
    let valueToRemain;
    Modal.confirm({
      title: `${strings.confirmPayment}. ${currency}${
        splitPaymentActive ? totalAmount : hasInstantRewardDiscount()
          ? Number(Number(totalAmountToBePaid) - instantRewardDiscount).toFixed(
              2
            )
          : Number(totalAmountToBePaid).toFixed(2)
      }`,
      cancelText: strings.cancel,
      content: (
        <div>
          <div>{strings.hasPaymentBeenMade}</div>
          <div className="mt-2">
            {splitPaymentActive &&<div style={{marginTop: 10}}>{strings.amountPaidSplit}</div>}
            {splitPaymentActive && <InputNumber
              type="text"
              id="transfer-reference-input"
              // className="mt-2"
              placeholder={strings.amountPaidSplit}
              required
              value={splitPaymentMethods.method1.method !== null ? Number(totalAmount).toFixed(2) : Number(amountRemaining).toFixed(2)}
              onChange={(value)=>{
                if(splitPaymentMethods.method1.method === null && value >= Number(totalAmountToBePaid)) return message.error("Cannot use split payment on complete amount")
                setAmountRemaining(value);
                valueToRemain = value;
                setTotalAmount(totalAmountToBePaid - value);
              }}
              disabled={splitPaymentMethods.method1.method !== null}
              style={{width: "100%"}}
            />}
            <Input
              type="text"
              id="transfer-reference-input"
              className="mt-2"
              placeholder={strings.transactionReference}
              required
            />
          </div>
        </div>
      ),
      onOk: () => {
        const transferReference = document.getElementById(
          "transfer-reference-input"
        ).value;

        if(splitPaymentActive){
          if(splitPaymentMethods && splitPaymentMethods.method1.method === null){
            // if(amountRemaining === null) return message.error("enter the amount transterred");
            setSplitPaymentMethods(prev => {
              return {
                ...prev,
                method1: {
                  method: "card",
                  amount: valueToRemain || amountRemaining
                }
              }
            });
            // console.log("hero",Number(totalAmountToBePaid), amountRemaining);
            // setTotalAmount(totalAmountToBePaid - amountRemaining)
            return setSelectCardTypeModalOpen(false);
          }else if (splitPaymentMethods && splitPaymentMethods.method2.method === null){
            setSplitPaymentMethods(prev => {
              return {
                ...prev,
                method2: {
                  method: "card",
                  amount: Number(totalAmount).toFixed(2)
                }
              }
            });
    
            if (hasDiscount || hasInstantRewardDiscount()) {
              handlePayment(
                `${splitPaymentMethods.method1.method} + card`,
                location,
                true,
                discountAmount || instantRewardDiscount,
                `${transferReference}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:card:${totalAmount}`,
                clearDiscount,
                transactionDate || Date.now(),
                new Date(transactionDate || Date.now()).getTime(),
              );
            } else {
              handlePayment(
                `${splitPaymentMethods.method1.method} + card`,
                location,
                false,
                null,
                `${transferReference}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-_method2:card:${totalAmount}`,
                clearDiscount,
                transactionDate || Date.now(),
                new Date(transactionDate || Date.now()).getTime()
              );
            }
          }
        }else{
          if (hasDiscount || hasInstantRewardDiscount()) {
            handlePayment(
              "card",
              location,
              true,
              discountAmount || instantRewardDiscount,
              transferReference,
              clearDiscount,
              transactionDate || Date.now(),
              new Date(transactionDate || Date.now()).getTime(),
            );
          } else {
            handlePayment(
              "card",
              location,
              false,
              null,
              transferReference,
              clearDiscount,
              transactionDate || Date.now(),
              new Date(transactionDate || Date.now()).getTime()
            );
          }
        }
      },
    });
  };

  const handlePayWithPoints = () => {
    if (!customer) {
      message.error(strings.pleaseSelectACustomer, 5);
      return;
    }
    handlePaymentWithPoint();
  };

  const clearDiscount = () => {
    setHasDiscount(false);
    setDiscountAmount(null);
    setCustomDiscountValue(null);
  };

  const toggleSaveDraftModal = () => setIsSaveDraftModalOpen((open) => !open);

  const handlePayWithCash = () => {
    if(splitPaymentActive){
      if(splitPaymentMethods && splitPaymentMethods.method1.method === null){
        if(Number(customerCash) >= Number(totalAmount)) return message.error("Cannot use split payment on complete amount")
        setSplitPaymentMethods(prev => {
          return {
            ...prev,
            method1: {
              method: "cash",
              amount: customerCash
            }
          }
        });
        setTotalAmount(totalAmountToBePaid - customerCash)
        return toggleShowCashForm(false);
      }else if (splitPaymentMethods && splitPaymentMethods.method2.method === null){
        if(Number(customerCash) !== Number(totalAmount)) return message.error("Please enter the remaining amount!")
        setSplitPaymentMethods(prev => {
          return {
            ...prev,
            method2: {
              method: "cash",
              amount: customerCash
            }
          }
        })

        if (hasDiscount || hasInstantRewardDiscount()) {
          handlePayment(
            `${splitPaymentMethods.method1.method} + cash`,
            location,
            hasDiscount || hasInstantRewardDiscount(),
            discountAmount || instantRewardDiscount,
            `${Math.round(Math.random() * 1000000)}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:cash:${customerCash}`,
            clearDiscount,
            transactionDate || Date.now(),
            new Date(transactionDate || Date.now()).getTime(),
          );
        } else {
          handlePayment(
            `${splitPaymentMethods.method1.method} + cash`,
            location,
            false,
            null,
            `${Math.round(Math.random() * 1000000)}_method1:${splitPaymentMethods.method1.method}:${splitPaymentMethods.method1.amount}-method2:cash:${customerCash}`,
            clearDiscount,
            transactionDate || Date.now(),
            new Date(transactionDate || Date.now()).getTime(),
          );
        }
      }

    }else {
      if (hasDiscount || hasInstantRewardDiscount()) {
        handlePayment(
          "cash",
          location,
          hasDiscount || hasInstantRewardDiscount(),
          discountAmount || instantRewardDiscount,
          "",
          clearDiscount,
          transactionDate || Date.now(),
          new Date(transactionDate || Date.now()).getTime(),
        );
      } else {
        handlePayment(
          "cash",
          location,
          false,
          null,
          "",
          clearDiscount,
          transactionDate || Date.now(),
          new Date(transactionDate || Date.now()).getTime(),
        );
      }
    }
  };

  const hide = () => {
    setIsVisible(false);
  };

  const handleVisibleInfoChange = (visibleInfo) => {
    setVisibleInfo(visibleInfo);
  };

  const handlePayWithGiftCard = (card) => {
    const totalPayable = hasInstantRewardDiscount()
      ? Number(totalAmountToBePaid - instantRewardDiscount).toFixed(2)
      : Number(totalAmountToBePaid).toFixed(2);
    if (card.amount < totalPayable)
      return Modal.error({
        title: `Gift card balance of NGN${card.amount} cannot purchase product, kindly topup and try again.`,
      });

    if(splitPaymentActive && splitPaymentMethods.method1.method === null && card.amount >= totalPayable )
      return Modal.error({
        title: `Cannot use split payment on complete amount!.`,
      });

      splitPaymentActive && splitPaymentMethods.method1.method === null && setTotalAmount(Number(totalPayable) - Number(card.amount))
      setAmountRemaining(card.amount);

    if(splitPaymentActive && splitPaymentMethods.method1.method !== null && card.amount !== totalAmount )
    return Modal.error({
      title: `Please enter the remaining amount!.`,
    });

    return editCard({
      variables: { data: { id: card.id, amount: card.amount - totalPayable } },
    });
  };

  // const showCustomDiscount = val => {
  //   setIsCustomDiscountEnabled(val);
  // };

  const toggleDiscount = (e) => {
    if (e.target.checked) {
      Modal.confirm({
        title: strings.areYouSureToApplyDiscount,
        okText: strings.yes,
        cancelText: strings.no,
        onOk: () => {
          setHasDiscount(true);
        },
        onCancel: () => {
          setHasDiscount(false);
        },
      });
    } else {
      setHasDiscount(false);
    }
  };

  /**
   *
   * @param {number} discount discount value in %
   * @param {number} amount total amount which discount would be removed from
   * @returns {number}
   */
  const calculateDiscount = (discount, amount) => {
    const total = (discount / 100) * amount;
    return total;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsCustomDiscountEnabled(false);
  };

  const handleCustomDiscountChange = (val) => {
    setDiscountAmount(null);
    setCustomDiscountValue(val);

    const totalAmount = getCartTotalAmount();
    // Update Cash Input Field
    const discountedAmount = calculateDiscount(val, totalAmount);
    setDiscountAmount(discountedAmount);
    const totalAmountToBePaid = totalAmount - discountedAmount;
    const amountPaid = instantRewardDiscount
      ? totalAmountToBePaid - instantRewardDiscount
      : totalAmountToBePaid;
    onCashValueChange(amountPaid, hasDiscount);
  };

  //
  const handleDiscountAmountChange = (val) => {
    setCustomDiscountValue(null);
    setDiscountAmount(val);
    const totalAmount = getCartTotalAmount();
    setDiscountAmount(Number(val));
    const totalAmountToBePaid = totalAmount - Number(val);
    onCashValueChange(totalAmountToBePaid, hasDiscount);
  };

  const handleDiscountTypeChange = (val) => {
    setDiscountAmount(null);
    setCustomDiscountValue(null);
    setDiscountType(val);
  };

  const handleVisibleInfoContent = (
    <div
      className="popover-content info-content"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      onClick={hide}
    >
      {" "}
      <div
        style={{
          fontSize: "15px",
          width: "150px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #ccc",
        }}
      >
        {" "}
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();

            history.push({
              pathname: "/d/app_store",
              state: {
                isFromCheckout: false,
              },
            });
          }}
        >
          {" "}
          {strings.addYourBankAccountToEnableUssd}{" "}
        </Link>{" "}
      </div>{" "}
    </div>
  );

  const onSplitPaymentChange = (e) => {
    const { checked } = e.target;
    
    activeSplitPayment(checked);
  }

  

  useEffect(()=>{
    if(splitPaymentActive){
      setTotalAmount(totalAmountToBePaid);
    }
  }, [splitPaymentActive]);

  if (invoicePrompt) {
    const customer = customers.find((item) => item.user_id === customerValue);

    return (
      <InvoiceForm
        selectedCustomer={selectedCustomer}
        cartList={cartList}
        addProductToCart={addProductToCart}
        removeItemFromCart={removeItemFromCart}
        reduceItemFromCart={reduceItemFromCart}
        handleShowBulkQuantityModal={handleShowBulkQuantityModal}
        toggleShowInvoicePrompt={toggleShowInvoicePrompt}
        type="create"
        user={user}
        getCartTotalAmount={getCartTotalAmount}
        invoicePrompt={invoicePrompt}
        visible={isRecordSalePaymentVisible}
        toggleShowCashForm={toggleShowCashForm}
        onCancel={onCancel}
        customerValue={customerValue}
        onCustomerSearch={onCustomerSearch}
        customers={customers}
        cleanupSelectCustomerDropdown={cleanupSelectCustomerDropdown}
        handleSaveInvoiceDone={handleSaveInvoiceDone}
        customer={customer}
        location={location}
        hasDiscount={hasDiscount}
        discountAmount={discountAmount}
        clearDiscount={clearDiscount}
      />
    );
  }

  const isCommission =
    user.commission === null ||
    (user.employer && user.employer.commission === null);

  const hasActivePayout =
    user.payout_active || (user.employer && user.employer.payout_active);

  const hasInstantRewardDiscount = () => {
    if (!customer) return false;
    if (topupGiftCard) return false;
    const customer_id = customer.id;
    if (!activeInstantRewardLoyalty) return false;
    else if (activeInstantRewardLoyalty.apply_to === "everyone")
      return checkLastRedemptionDate(customer, activeInstantRewardLoyalty);
    else if (
      activeInstantRewardLoyalty.apply_to === "set" &&
      activeInstantRewardLoyalty.instant_rewards_groups
    ) {
      const groupObj = {};
      const customer_groups = JSON.parse(
        localStorage.getItem("customer_groups")
      );
      customer_groups &&
        customer_groups.forEach((group) => (groupObj[group.id] = group));
      const customerIDs = [];
      activeInstantRewardLoyalty.instant_rewards_groups.forEach((rewardGroup) =>
        groupObj[rewardGroup.group_id]
          ? customerIDs.push(groupObj[rewardGroup.group_id])
          : ""
      );
      const idObj = {};
      customerIDs.forEach((idGroup) =>
        idGroup.customers.forEach((id) => (idObj[id] = id))
      );

      return idObj[customer_id]
        ? checkLastRedemptionDate(customer, activeInstantRewardLoyalty)
        : false;
    } else return false;

    function checkLastRedemptionDate(customer, activeInstantRewardLoyalty) {
      if (
        customer.customer_eligibilities &&
        customer.customer_eligibilities.length > 0 &&
        customer.customer_eligibilities[0].merchant_loyalty_program_id ===
          activeInstantRewardLoyalty.id
      ) {
        if (activeInstantRewardLoyalty.eligibility_period) {
          return (
            new Date().getTime() >
            new Date(
              customer.customer_eligibilities[0].last_redeemed_date
            ).getTime() +
              activeInstantRewardLoyalty.eligibility_period * 86400000
          );
        } else return true;
      } else return true;
    }
  };

  const discount_amount =
    discountType === "amount"
      ? discountAmount
      : calculateDiscount(
          customDiscountValue || discountValue,
          getCartTotalAmount()
        );

  const instantRewardDiscount = activeInstantRewardLoyalty
    ? (getCartTotalAmount() * Number(activeInstantRewardLoyalty.reward_value)) /
      100
    : 0;

  

  const currency = user.currency || (user.employer && user.employer.currency);
  const staffBranch = user.business_branch ? user.business_branch : null;
  const isKycVerified = user.employer
    ? user.employer.bvn_verified
    : user.bvn_verified;

  const currentTerminal = currentTerminalId
    ? terminalData.find((one) => one.terminal_id === currentTerminalId)
    : null;

  // const isAdmin = user.role_id === 1;
 

  return (
    <div>
      <>
      {<PartnerGiftcardModal 
        open={partnerGiftCard}
        onCancel={()=>setPartnerGiftcard(false)}
        handlePayment={handlePayment}
        location={location}
        user={user}
        clearDiscount={clearDiscount}
        transactionDate={transactionDate}
        getCartTotalAmount={getCartTotalAmount}
        recordingGiftCardSale={recordingGiftCardSale}
        cartList={cartList}
      />}
        {cancelModal && (
          <Modal
            open={cancelModal}
            width={400}
            onCancel={() => setCancelModal(false)}
            footer={[
              <Button key="no" onClick={() => setCancelModal(false)}>
                No
              </Button>,
              <Button key="edit" type="ghost" onClick={() =>{
                onCancel()
                handleBlurFactor(true);
              }}>
                Edit Products
              </Button>,
              <Button
                type="primary"
                key="yes"
                onClick={() => {
                  toggleShowCashForm(false, "modal-cancel");
                  clearTopupGiftCard();
                  onCancel();
                  setHasDiscount(false);
                  // handleSelectDiscount(0);
                  setCustomDiscountValue("");
                  clearCart();
                  handleBlurFactor(true)
                }}
              >
                Yes
              </Button>,
            ]}
          >
            <h3>
                <InfoCircleOutlined /> {strings.doYouWantToCancelTransaction}
            </h3>
          </Modal>
        )}
        {openCredPalModal 
          && 
          <CredpalProcessModal 
            open={openCredPalModal}
            onCancel={()=>setOpenCredPalModal(false)}
            customer={customer}
            cartList={cartList}
            totalAmount={totalAmountToBePaid}
            user={user}
            location={location}
            selectedCustomer={selectedCustomer}
            customerValue={customerValue}
            clearSelectedCustomer={clearSelectedCustomer}
            clearCart={clearCart}
            setPaymentType={setPaymentType}
            isRecordSalePaymentVisible={isRecordSalePaymentVisible}
            toggleTransactionModal={toggleTransactionModal}
          />
        }
        {verifyVisible && (
          <VerifyTokenModal
            visible={verifyVisible}
            phoneNumber={customer.phone_number}
            closeModal={() => setVerifyVisible(false)}
            onCancel={() => setVerifyVisible(false)}
            isRequired={true}
            onOk={() => verifyFunction()}
            content={verifyModalContent}
          />
        )}
        <Modal
          title={`
              ${strings.totalAmountToPay} ${currency}${splitPaymentActive ? totalAmount : numberFormatter(
            hasInstantRewardDiscount()
              ? Number(totalAmountToBePaid - instantRewardDiscount).toFixed(2)
              : Number(totalAmountToBePaid).toFixed(2)
          )}${
            invoicePrompt
              ? selectedCustomer
                ? strings.for + selectedCustomer
                : ""
              : ""
          }

          ${
            hasDiscount && discountAmount
              ? `(${currency}${Number(discountAmount).toFixed(2)} ${strings.wasDeducted})`
              : ""
          }
          ${
            hasInstantRewardDiscount()
              ? `(${Number(instantRewardDiscount).toFixed(2)} was deducted)`
              : ""
          }
            `}
          open={isRecordSalePaymentVisible}
          onCancel={() => setCancelModal(true)}
          footer={[
            <div className="custom-footer" key="1">
              <Discount
                hasDiscount={hasDiscount}
                setHasDiscount={setHasDiscount}
                toggleDiscount={toggleDiscount}
                customDiscountValue={customDiscountValue}
                handleCustomDiscountChange={handleCustomDiscountChange}
                handleSubmit={handleSubmit}
                discountType={discountType}
                handleDiscountTypeChange={handleDiscountTypeChange}
                handleDiscountAmountChange={handleDiscountAmountChange}
                discountAmount={discountAmount}
                user={user}
              />
              <div className="split-payment">
                <Checkbox onChange={onSplitPaymentChange} disabled={splitPaymentMethods.method1.method !== null}/>
                <p>Split Payment</p>
              </div>
              <div
                className="btn"
                style={{ display: "flex", alignItems: "center" }}
              >
                {isAdminIsManager && (
                  <Tooltip
                    placement="left"
                    title={
                      <p style={{ fontSize: 12 }}>
                        {transactionDate
                          ? `Sale backdated to ${new Date(
                              transactionDate
                            ).toLocaleString()}`
                          : "Backdate sale date"}
                      </p>
                    }
                  >
                    <Link to="#" key="calendar" style={{ marginRight: 40 }}>
                      <DatePicker
                        showTime={{ format: "hh mm a" }}
                        bordered={false}
                        className="backdate_calendar"
                        placeholder=""
                        style={{ width: 20, marginTop: 3, fontSize: 25 }}
                        onChange={(val, str) => setTransactionDate(str)}
                      >
                        <CalendarOutlined />
                      </DatePicker>
                    </Link>
                  </Tooltip>
                )}
                {!isNitroActive &&
                <Link
                  to="#"
                  key="save"
                  onClick={(e) => {
                    e.preventDefault();

                    toggleSaveDraftModal();
                  }}
                  style={{display: "flex", alignItems:"center", gap:3}}
                >
                  <Icon style={{ marginRight: "5px", fontSize: 25 }} type="printer" />{" "}
                  <p style={{margin:0}}>{strings.savePrintBill}</p>
                </Link>}
              </div>
            </div>,
          ]}
          width={600}
          destroyOnClose
        >
          <div id="pay">
            {showCashForm ? (
              <div className="pay-with-cash">
                <InputNumber
                  parser={(value) => value.replace(/\bNGN\s?|(, *)/g, "")}
                  style={{
                    width: "100%",
                    marginBottom: "1.2rem",
                  }}
                  placeholder={strings.enterAmountCollectedForCustomer}
                  onChange={onCashValueChange}
                  type="number"
                  value={customerCash}
                />
                <p style={{ marginTop: "10px" }}>
                  Customer's Change:{" "}
                  {`${user.currency ? user.currency : ""}${numberFormatter(
                    Number(cashChange).toFixed(2)
                  )}`}
                </p>
                <Button
                  type="primary"
                  loading={recordingCashSale || getOtpLoading}
                  onClick={() => verifyOTP(handlePayWithCash)}
                >
                  {" "}
                  {strings.recordSale}
                </Button>
                <Button
                  className="ml-20"
                  onClick={() => toggleShowCashForm(!showCashForm)}
                >
                  {" "}
                  {strings.cancel}{" "}
                </Button>{" "}
              </div>
            ) : (
              <>
                {" "}
                <h3 className="title">{strings.checkOutWith}</h3>{" "}
                <div className="pay-buttons">
                  {" "}
                  {!topupGiftCard && (
                    <Button
                      onClick={() => toggleShowCashForm(!showCashForm)}
                      disabled={disabledCashButtonEmails.includes(user.email) || splitPaymentMethods.method1.method === "cash"}
                    >
                      {" "}
                      <Icon type="wallet" /> {strings.cash}{" "}
                    </Button>
                  )}
                  {!topupGiftCard && (
                    <Button 
                      onClick={() => setSelectCardTypeModalOpen(true)}
                      disabled={splitPaymentMethods.method1.method === "card"}
                    >
                      {" "}
                      <Icon type="credit-card" /> {strings.card}{" "}
                    </Button>
                  )}
                  {(user.role_id === 1 ||
                    user.role.id === 2 ||
                    user.role.id === 3) &&
                    !topupGiftCard && (
                      <Button
                        disabled={disabledInvoiceButtonEmails.includes(
                          user.email
                        ) || checkoutData === "services"}
                        onClick={() => toggleShowInvoicePrompt(true)}
                      >
                        {" "}
                        <Icon type="book" /> {strings.invoice}{" "}
                      </Button>
                    )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        splitPaymentActive && splitPaymentMethods.method1.method !== null && setAmountRemaining(Number(totalAmount).toFixed(2));
                        setSelectVpayUpayModalOpen(true)
                      }}
                      style={{
                        width: "100%",
                      }}
                      disabled={disabledBankTransferButtonEmails.includes(
                        user.email
                      )|| splitPaymentMethods.method1.method === "m_transfer"}
                    >
                      {" "}
                      <Icon type="mobile" />
                      {strings.instantTransfer}
                    </Button>

                    {!isCommission && !hasActivePayout && (
                      <Popover
                        content={handleVisibleInfoContent}
                        trigger="hover"
                        visible={visibleInfo}
                        onVisibleChange={handleVisibleInfoChange}
                      >
                        {" "}
                        <Icon
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginLeft: "10px",
                          }}
                          type="info-circle"
                        />{" "}
                      </Popover>
                    )}
                  </div>
                  {!topupGiftCard && (
                    <Button
                      onClick={() => verifyOTP(handlePayWithPoints)}
                      loading={isPayingWithPoints}
                      disabled={
                        disabledPointPaymentEmails.includes(user.email) ||
                        isSubscribedToMultiLevel === false ||
                        getOtpLoading
                      }
                    >
                      {" "}
                      <Icon type="star" />
                      Points{" "}
                    </Button>
                  )}
                  {!topupGiftCard && (
                    <Button
                      onClick={() => setSelectGiftCard(true)}
                      style={{
                        width: "100%",
                      }}
                      loading={editCardLoading || recordingGiftCardSale}
                      disabled={splitPaymentMethods.method1.method === "gift_card"}
                    >
                      {" "}
                      <Icon type="credit-card" /> Gift Card
                    </Button>
                  )}
                  {isKitchenScreenActive && (
                    <Button
                      onClick={() => handleAddToKitchScreen()}
                      style={{
                        width: "100%",
                        padding: 0
                      }}
                      loading={instoreLoading || addOrderLoading}
                    >
                      {" "}
                      <Icon type="credit-card" /> {strings.sendKitchenScreen}
                    </Button>
                  )}
                  {isCredPalActive && (
                    <Button
                      onClick={() => setOpenCredPalModal(true)}
                      loading={isPayingWithPoints}
                      disabled={!customer || splitPaymentActive}
                    >
                      <Image src={credPalLogo} alt="credpal logo" />{" "}
                      CredPal{" "}
                    </Button>
                  )}
                </div>{" "}
              </>
            )}
          </div>{" "}
          {hasInstantRewardDiscount() && (
            <p
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
                fontSize: "15px",
              }}
            >
              <em>
                {activeInstantRewardLoyalty.name} instant reward is active,{" "}
                {activeInstantRewardLoyalty.reward_value}%{" "}
                {activeInstantRewardLoyalty.reward} is applied 🎁
              </em>
            </p>
          )}
        </Modal>

        {selectGiftCard && (
          <Modal
            title="Select Gift Card"
            open={selectGiftCard}
            onCancel={() => setSelectGiftCard(false)}
            footer={[
              <Button onClick={() => setSelectGiftCard(false)}>Cancel</Button>,
            ]}
          >
            {customer &&
              giftCardCustomers[customer.id] &&
              giftCardCustomers[customer.id].map((card) => (
                <p>
                  <Button
                    onClick={() => handlePayWithGiftCard(card)}
                  >{`${card.card_number} - NGN${card.amount} balance`}</Button>
                </p>
              ))}
            {(!customer || !giftCardCustomers[customer.id]) && (
              <div>
                <Select
                  allowClear
                  showSearch
                  placeholder="Select Gift Card"
                  optionFilterProp="children"
                  onChange={(val) => {
                    const card = listCards?.listGiftCards.find(
                      (one) => one.card_number === val
                    );
                    handlePayWithGiftCard(card);
                  }}
                  size="large"
                  style={{ width: "80%" }}
                  filterOption={(input, option) => {
                    return (
                      option.props.children.indexOf(input) >= 0 ||
                      (option.props.id && option.props.id.indexOf(input) >= 0)
                    );
                  }}
                  disabled={false}
                >
                  {!listCardLoading &&
                    (listCards?.listGiftCards.length !== 0 && listCards?.listGiftCards.map((card) => (
                      <Select.Option
                        key={card.id}
                        value={card.card_number}
                        id={card.id}
                      >
                        {card.card_number}
                      </Select.Option>)
                    ))}
                </Select>
                <Button 
                  onClick={()=>{
                    setPartnerGiftcard(true);
                  }}
                  style={{marginTop: "0.5rem"}}
                >Pay with Partner's Giftcard</Button>
              </div>
            )}
          </Modal>
        )}

        {openAwaitPaystackPaymentModal && (
          <PaystackTransfer
            visible={!!openAwaitPaystackPaymentModal}
            closeModal={() => setOpenAwaitPaystackPaymentModal(false)}
            user={user}
            terminalData={currentTerminal}
            totalAmount={numberFormatter(
              hasInstantRewardDiscount()
                ? Number(totalAmountToBePaid - instantRewardDiscount).toFixed(2)
                : Number(totalAmountToBePaid).toFixed(2)
            )}
            hasDiscount={hasDiscount}
            discountAmount={discountAmount}
            handlePayment={handlePayment}
            transactionDate={transactionDate}
            clearDiscount={clearDiscount}
            hasInstantRewardDiscount={hasInstantRewardDiscount}
            location={location}
            instantRewardDiscount={instantRewardDiscount}
            paystackInvoiceData={paystackInvoiceData}
            onCancel={onCancel}
            splitPaymentActive={splitPaymentActive}
            splitPaymentMethods={splitPaymentMethods} 
            setSplitPaymentMethods={setSplitPaymentMethods}
          />
        )}

        {selectCardTypeModalOpen && (
          <Modal
            open={selectCardTypeModalOpen}
            footer={[
              <Button onClick={() => setSelectCardTypeModalOpen(false)}>
                Cancel
              </Button>,
            ]}
            title="Pay with Card"
            onCancel={() => setSelectCardTypeModalOpen(false)}
          >
            <div>
              <div style={{ margin: "20px" }}>
                {isKycVerified && terminalData.length > 0 ? (
                  !paystackInvoiceData &&
                  terminalData.map(
                    (terminal) =>
                      !!terminal.terminal_id && (
                        <div style={{ margin: "20px 0" }}>
                          <Button
                            onClick={() =>
                              initiatePaystackInvoice(terminal.terminal_id)
                            }
                            disabled={!terminal}
                            loading={getTerminalDataLoading}
                            style={{
                              width: "100%",
                            }}
                          >
                            {" "}
                            <Icon type="credit-card" />{" "}
                            <span style={{ textTransform: "capitalize" }}>
                              {terminal?.terminal_provider} Terminal -{" "}
                              {terminal?.terminal_label}
                            </span>
                            <Badge
                              style={{ marginLeft: 10 }}
                              status={
                                terminal?.terminal_provider === "payforce" ||
                                !!terminal?.presence?.online
                                  ? "success"
                                  : "error"
                              }
                            />
                          </Button>
                        </div>
                      )
                  )
                ) : (
                  <>
                    <Button
                      disabled={true}
                      style={{
                        width: "95%",
                      }}
                    >
                      <span>Request Terminal.</span>
                    </Button>
                    <Tooltip
                      title={
                        <p>
                          Go to <a href="/d/app_store">App Store</a> to request
                          a terminal
                        </p>
                      }
                    >
                      <span style={{ marginLeft: 5 }}>
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </>
                )}
                {isKycVerified &&
                  terminalData.filter((terminal) => !!terminal.terminal_id)
                    .length < 1 && (
                    <>
                      <Button
                        disabled={true}
                        style={{
                          width: "95%",
                        }}
                      >
                        <span>Request Terminal.</span>
                      </Button>
                      <Tooltip
                        title={
                          <p>
                            Go to <a href="/d/app_store">App Store</a> to
                            request a terminal
                          </p>
                        }
                      >
                        <span style={{ marginLeft: 5 }}>
                          <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    </>
                  )}
                {paystackInvoiceData && (
                  <Button
                    onClick={resendPaystackInvoice}
                    disabled={!paystackInvoiceData}
                    loading={resendInvoiceLoading}
                    style={{
                      width: "100%",
                    }}
                    title="Resend invoice when available"
                  >
                    Resend Amount to Terminal
                    <RedoOutlined />
                    <Badge
                      style={{ marginLeft: 10 }}
                      status={
                        currentTerminal?.terminal_provider === "payforce" ||
                        !!currentTerminal?.presence?.online
                          ? "success"
                          : "error"
                      }
                    />
                  </Button>
                )}
              </div>
            </div>

            {!topupGiftCard && (
              <div style={{ margin: "20px" }}>
                <Button
                  onClick={() => verifyOTP(handlePayWithCard)}
                  loading={recordingCardSale}
                  disabled={
                    disabledCardButtonEmails.includes(user.email) ||
                    getOtpLoading
                  }
                  style={{
                    width: "100%",
                  }}
                >
                  {" "}
                  <Icon type="credit-card" /> Other Card Terminals
                </Button>
              </div>
            )}
          </Modal>
        )}

        {selectVpayUpayModalOpen && (
          <Modal
            open={selectVpayUpayModalOpen}
            footer={[
              <Button onClick={() => setSelectVpayUpayModalOpen(false)}>
                Cancel
              </Button>,
            ]}
            title="Pay with Bank Transfer"
            onCancel={() => setSelectVpayUpayModalOpen(false)}
          >
            
            <div>
              {splitPaymentActive && <InputNumber
                type="text"
                id="transfer-reference-input"
                // className="mt-2"
                placeholder={strings.amountPaidSplit}
                required
                value={splitPaymentMethods.method1.method !== null ? Number(totalAmount).toFixed(2) : amountRemaining}
                onChange={(value)=>{
                  if(splitPaymentMethods.method1.method === null && value >= Number(totalAmountToBePaid)) return message.error("Cannot use split payment on complete amount");
                  // if(splitPaymentMethods.method1 !== null && value !== Number(totalAmount)) return message.error("Please enter the remaining amount");
                  setAmountRemaining(value);
                  setProceedPayment(true);
                }}
                disabled={splitPaymentMethods.method1.method !== null}
                style={{width: "91.5%", marginLeft: "20px"}}
              />}
              {staffBranch ? (
                <div style={{ margin: "20px" }}>
                  <Button
                    onClick={() => {
                      if(splitPaymentActive && amountRemaining === null) return message.error("Enter amount to be transferred");
                      setIsVpayModalOpen(true);
                      setIsTransferRouteActive(true);
                      setPaymentMethod("bank_transfer");
                      isCashier ? setBranchNuban(cashierTill.nuban) : setBranchNuban(staffBranch.nuban);
                      isCashier ? setBranchQr(cashierTill.qrcode) : setBranchQr(staffBranch.qrcode); 
                      isCashier ? setBankNubanName(cashierTill) : setBankNubanName(staffBranch)
                    }}
                    loading={loading}
                    style={{
                      width: "100%",
                    }}
                  >
                    {" "}
                    <Icon type="mobile" />{" "}
                    {`Instant Transfer - ${isCashier ? cashierTill.nuban : staffBranch.nuban ? staffBranch.nuban : "<No AccNo>"} (VFD Bank)`}
                  </Button>
                </div>
              ) : (
                allBranches &&
                allBranches.map((branch) =>
                  !!branchesAccountNumber[branch.id]?.nuban ? (
                    <div style={{ margin: "20px" }}>
                      <Button
                        onClick={() => {
                          if(splitPaymentActive && amountRemaining === null) return message.error("Enter amount to be transferred");
                          splitPaymentActive && splitPaymentMethods.method1.method === null && setTotalAmount(Number(totalAmountToBePaid) - amountRemaining);
                          setIsVpayModalOpen(true);
                          setIsTransferRouteActive(true);
                          setPaymentMethod("bank_transfer");
                          setBranchNuban(
                            branchesAccountNumber[branch.id]?.nuban
                          );
                          setBranchQr(branchesAccountNumber[branch.id]?.qrcode);
                        }}
                        disabled={!branchesAccountNumber[branch.id]?.nuban}
                        loading={loading}
                        style={{
                          width: "100%",
                        }}
                      >
                        {" "}
                        <Icon type="mobile" />{" "}
                        {`Instant Transfer to - ${
                          branchesAccountNumber[branch.id]?.nuban ||
                          "<No AccNo>"
                        } (VFD Bank)`}
                      </Button>
                    </div>
                  ) : null
                )
              )}
              <div style={{ margin: "20px" }}>
                <Button
                  onClick={() => {
                    if(splitPaymentActive && amountRemaining === null) return message.error("Enter amount to be transferred");
                    splitPaymentActive && splitPaymentMethods.method1.method === null && setTotalAmount(Number(totalAmountToBePaid) - amountRemaining);
                    toggleBankTransferModal(true);
                    setIsTransferRouteActive(true);
                    setPaymentMethod("bank_transfer");
                  }}
                  disabled={isCommission && !hasActivePayout}
                  style={{
                    width: "100%",
                  }}
                >
                  {" "}
                  <Icon type="mobile" />
                  Instant Transfer with USSD
                </Button>
              </div>
              {payStackDetails && <div style={{ margin: "20px" }}>
                <Button
                  onClick={() => {
                    if(splitPaymentActive && amountRemaining === null) return message.error("Enter amount to be transferred");
                    splitPaymentActive && splitPaymentMethods.method1.method === null && setTotalAmount(Number(totalAmountToBePaid) - amountRemaining);
                    setIsTransferRouteActive(true);
                    setPaymentMethod("bank_transfer");
                    setOpenPaystackPaymentModal(true);
                  }}
                  disabled={isCommission && !hasActivePayout}
                  style={{
                    width: "100%",
                  }}
                >
                  {" "}
                  <Icon type="mobile" />
                  Pay with paystack virtual terminal
                </Button>
              </div>}
              <div style={{ margin: "20px" }}>
                {!topupGiftCard && !isDisableNewFreePlanAccounts && (
                  <Button
                    onClick={() => {
                      if(splitPaymentActive && splitPaymentMethods.method1.method === null && amountRemaining === null) return message.error("Enter the amount to be paid!")
                      splitPaymentActive && splitPaymentMethods.method1.method === null && setTotalAmount(Number(totalAmountToBePaid) - amountRemaining);
                      verifyOTP(handlePayWithManualTransfer)
                    }}
                    style={{
                      width: "100%",
                    }}
                    loading={recordingManualTransfer}
                    disabled={
                      disabledBankTransferButtonEmails.includes(user.email) ||
                      getOtpLoading
                    }
                  >
                    {" "}
                    <Icon type="bank" /> {strings.bankTransfer}
                  </Button>
                )}
              </div>
            </div>
          </Modal>
        )}

        {isVpayModalOpen && (
          <VpayTransfer
            visible={isVpayModalOpen}
            toggleTransactionModal={toggleTransactionModal}
            toggleVpayTransferModal={() => setIsVpayModalOpen(false)}
            selectedCustomer={selectedCustomer}
            cartList={cartList}
            type="create"
            user={user}
            location={location}
            getCartTotalAmount={getCartTotalAmount}
            customerValue={customerValue}
            onCustomerSearch={onCustomerSearch}
            customers={customers}
            cleanupSelectCustomerDropdown={cleanupSelectCustomerDropdown}
            customer={customer}
            clearCart={clearCart}
            clearSelectedCustomer={clearSelectedCustomer}
            isRecordSalePaymentVisible={isRecordSalePaymentVisible}
            paymentType={paymentType}
            setPaymentType={setPaymentMethod}
            isTransferModalOpen={isTransferModalOpen}
            hasDiscount={hasDiscount}
            hasInstantRewardDiscount={hasInstantRewardDiscount()}
            instantRewardDiscount={instantRewardDiscount}
            discountAmount={discountAmount}
            clearDiscount={clearDiscount}
            isTwoPaymentMethod={isTwoPaymentMethod}
            mtier_amount={mtier_amount}
            categories={categories}
            loyalty_id={loyalty_id}
            handlePayment={handlePayment}
            qrcode={branchQr}
            accountNumber={branchNuban}
            topupGiftCard={topupGiftCard}
            clearTopupGiftCard={clearTopupGiftCard}
            cardId={cardId}
            transactionDate={transactionDate}
            splitPaymentActive={splitPaymentActive}
            splitPaymentMethods={splitPaymentMethods}
            setSplitPaymentMethods={setSplitPaymentMethods}
          />
        )}

        {isTransferModalOpen ? (
          <BankTransferModal
            visible={isTransferModalOpen}
            toggleTransactionModal={toggleTransactionModal}
            toggleBankTransferModal={toggleBankTransferModal}
            selectedCustomer={selectedCustomer}
            cartList={cartList}
            type="create"
            user={user}
            location={location}
            getCartTotalAmount={getCartTotalAmount}
            customerValue={customerValue}
            onCustomerSearch={onCustomerSearch}
            customers={customers}
            cleanupSelectCustomerDropdown={cleanupSelectCustomerDropdown}
            customer={customer}
            clearCart={clearCart}
            clearSelectedCustomer={clearSelectedCustomer}
            isRecordSalePaymentVisible={isRecordSalePaymentVisible}
            paymentType={paymentType}
            setPaymentType={setPaymentMethod}
            isTransferModalOpen={isTransferModalOpen}
            hasDiscount={hasDiscount}
            hasInstantRewardDiscount={hasInstantRewardDiscount()}
            instantRewardDiscount={instantRewardDiscount}
            discountAmount={discountAmount}
            clearDiscount={clearDiscount}
            isTwoPaymentMethod={isTwoPaymentMethod}
            mtier_amount={mtier_amount}
            loyalty_id={loyalty_id}
            splitPaymentActive={splitPaymentActive}
            splitPaymentMethods={splitPaymentMethods}
            setSplitPaymentMethods={setSplitPaymentMethods}
          />
        ) : null}

        {openPaystackPaymentModal &&
          <PaystackPaymentModal 
            user={user}
            open={openPaystackPaymentModal}
            onCancel={()=>setOpenPaystackPaymentModal(false)}
            location={location}
            selectedCustomer={selectedCustomer}
            customerValue={customerValue}
            clearSelectedCustomer={clearSelectedCustomer}
            clearCart={clearCart}
            setPaymentType={setPaymentMethod}
            cartList={cartList}
            isTwoPaymentMethod={isTwoPaymentMethod}
            getCartTotalAmount={getCartTotalAmount}
            mtier_amount={mtier_amount}
            isRecordSalePaymentVisible={isRecordSalePaymentVisible}
            toggleTransactionModal={toggleTransactionModal}
            hasInstantRewardDiscount={hasInstantRewardDiscount()}
            instantRewardDiscount={instantRewardDiscount}
            hasDiscount={hasDiscount}
            discountAmount={discountAmount}
            handlePayment={handlePayment}
            clearDiscount={clearDiscount}
            splitPaymentActive={splitPaymentActive}
            setSplitPaymentMethods={setSplitPaymentMethods}
            splitPaymentMethods={splitPaymentMethods}
            amountRemaining={amountRemaining}
            totalAmount={totalAmount}
          />
        }

        {isSaveDraftModalOpen ? (
          <SaveDraftModal
            isSaveDraftModalOpen={isSaveDraftModalOpen}
            toggleSaveDraftModal={toggleSaveDraftModal}
            cartList={cartList}
            customer={customer}
            customers={customers}
            selectedCustomer={selectedCustomer}
            total={getCartTotalAmount()}
            user={user}
            allLoyaltyPrograms={allLoyaltyPrograms}
            toggleTransactionModal={toggleTransactionModal}
            clearCart={clearCart}
            discountAmount={discountAmount}
            has_Discount={hasDiscount}
            currency={currency}
          />
        ) : null}
      </>
    </div>
  );
};

RecordSalePaymentModal.propTypes = {
  user: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCashValueChange: PropTypes.func.isRequired,
  getCartTotalAmount: PropTypes.func.isRequired,
  recordingCardSale: PropTypes.bool.isRequired,
  recordingCashSale: PropTypes.bool.isRequired,
  cashChange: PropTypes.number.isRequired,
  handlePayment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  allBranches: state.branch.allBranches,
  isNitroActive: state.appStore.isNitroActive,
  isKitchenScreenActive: state.appStore.isKitchenScreenActive,
  isPaystackTerminalActive: state.appStore.isPaystackTerminalActive,
  splitPaymentActive: state.sale.splitPaymentActive,
  isCredPalActive: state.appStore.isCredPalActive
});

export default connect(mapStateToProps, {
  getAllBusinessBranches,
  activeSplitPayment
})(RecordSalePaymentModal);