export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_ERRORS = "GET_ERROR";
export const RESET_ERROR = "RESET_ERROR";
export const SET_NETWORK_STATUS = "SET_NETWORK_STATUS";

// Products
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_TOTAL_PRODUCTS_COUNT = "GET_TOTAL_PRODUCTS_COUNT";
export const GET_PAGE_PRODUCTS = "GET_PAGE_PRODUCTS";
export const GET_ALL_BRANCH_PRODUCTS = "GET_ALL_BRANCH_PRODUCTS";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";

// Product Supplies
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const GET_PRODUCT_SUPPLIES = "GET_PRODUCT_SUPPLIES";
export const UPDATE_PRODUCT_SUPPLIES = "UPDATE_PRODUCT_SUPPLIES";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const ADD_PRODUCT_SUPPLIES = "ADD_PRODUCT_SUPPLIES";

// Categories
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";

// Customers
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const ADD_LOYALTY_CUSTOMERS = "ADD_LOYALTY_CUSTOMERS";
export const SET_NEW_CUSTOMER = "SET_NEW_CUSTOMER";

// Sales
export const RECORD_SALE = "RECORD_SALE";
export const SET_SALE_TRANSACTION_INFO = "SET_SALE_TRANSACTION_INFO";
export const SHOW_VARIANT_MODAL = "SHOW_VARIANT_MODAL";
export const SHOW_CUSTOMQTY_MODAL = "SHOW_CUSTOMQTY_MODAL";
export const CLEAR_SHOW_VARIANT_MODAL_DATA = "CLEAR_SHOW_VARIANT_MODAL_DATA";
export const CLEAR_SHOW_CUSTOMQTY_MODAL_DATA = "CLEAR_SHOW_CUSTOMQTY_MODAL_DATA"
export const HAS_RECORDED_SALE = "HAS_RECORDED_SALE";
export const GET_SALES_DETAILS = "GET_SALES_DETAILS";
export const GET_ALL_SALES = "GET_ALL_SALES";

// Branches
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const LOADING_ALL_BRANCHES = "LOADING_ALL_BRANCHES"
export const SET_NEW_STAFF = "SET_NEW_STAFF";
export const SET_NEW_BRANCH = "SET_NEW_BRANCH";

// Loyalty Programs
export const GET_ALL_LOYALTY_PROGRAMS = "GET_ALL_LOYALTY_PROGRAMS";

// invoices
export const GET_ALL_INVOICES = "GET_ALL_INVOICES";

export const GET_PAYMENT_MESSAGE = "GET_PAYMENT_MESSAGE";

// birthday offers and messages

export const GET_OFFERS = "GET_OFFERS";
export const DELETE_OFFERS = "DELETE_OFFERS";
export const SAVE_OFFERS = "SAVE_OFFERS";
export const GET_BIRTHDAY_MESSAGE = "GET_BIRTHDAY_MESSAGE";
export const EDIT_BIRTHDAY_MESSAGE = "EDIT_BIRTHDAY_MESSAGE";

// Account Renewal and Upgrade
export const TOGGLE_RENEWAL_MODAL = "TOGGLE_RENEWAL_MODAL";

export const TOGGLE_SUBSCRIBE_MODAL = "TOGGLE_SUBSCRIBE_MODAL";

export const SUBSCRIPTION_STATUS = "SUBSCRIPTION_STATUS"

// Multi-Level Loyalty Subscription Status
export const CHANGE_MULTILEVEL_STATUS = "CHANGE_MULTILEVEL_STATUS";
export const CHANGE_BOOKING_SUBSCRIPTION = "CHANGE_BOOKING_SUBSCRIPTION";

// APP STORE
export const FETCH_ALL_APPS = "FETCH_ALL_APPS";
export const FETCH_SUBSCRIBED_APPS = "FETCH_SUBSCRIBED_APPS";

// merchant
export const GET_MERCHANT_PLAN_DATE = "GET_MERCHANT_PLAN_DATE";

//orders
export const GET_ORDERS = "GET_ORDERS";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";

//language
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";


//merchants detials
export const MERCHANT_DETAILS = "MERCHANT_DETAILS";

//get ordered Items
export const ORDERED_ITEMS = "ORDERED_ITEMS";
export const ORDERED_ITEMS_LOADING = "ORDERED_ITEMS_LOADING";
export const ORDERED_ITEMS_SUCCESS = "ORDERED_ITEMS_SUCCESS"; 

//save all orders to state
export const ORDERS = "ORDERS";

//notify through orders
export const DISPLAY_ORDERS = "DISPLAY_ORDERS";

//payorders
export const PAY_ORDER = "PAY_ORDER";

//handle nitro
export const SET_NITRO = "SET_NITRO";

//handle Product Bundle
export const SET_PRODUCT_BUNDLE = "SET_PRODUCT_BUNDLE"; 

export const SET_PAYSTACK_TERMINAL = "SET_PAYSTACK_TERMINAL";

export const SET_BEEP_BEEP = "SET_BEEP_BEEP";

export const SET_STRIP_FOR_LOYSTAR = "SET_STRIP_FOR_LOYSTAR";

export const SET_STRIP_TERMINAL = "SET_STRIP_TERMINAL";

export const SET_STRIP_LOYALTY = "SET_STRIP_LOYALTY";

export const SET_WHATSAPP_ACTIVE = "SET_WHATSAPP_ACTIVE"

export const SET_ORDER_KITCHEN_SCREEN = "SET_ORDER_KITCHEN_SCREEN";

export const OPEN_ORDER_SCREEN = "OPEN_ORDER_SCREEN";

export const SUBSCRIPTION_ACCESS = "SUBSCRIPTION_ACCESS";

export const MY_VALENTINE_PLUG_ACTIVE = "MY_VALENTINE_PLUG_ACTIVE";

export const SPLIT_PAYMENT_ACTIVE = "SPLIT_PAYMENT_ACTIVE";

export const CREDPAL_ACTIVE = "CREDPAL_ACTIVE";

export const LEDGER_ACTIVE = "LEDGER_ACTIVE";

export const WHATSAPP_REFERRAL_ACTIVE = "WHATSAPP_REFERRAL_ACTIVE";

export const AIRTIME_REWARDS = "AIRTIME_REWARDS";

export const PURCHASE_ORDER = "PURCHASE_ORDER";