import {
  GET_ALL_BRANCH_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_PAGE_PRODUCTS,
  GET_PRODUCT_SUPPLIES,
  GET_SUPPLIERS,
  GET_TOTAL_PRODUCTS_COUNT,
  UPDATE_PRODUCTS
} from "../constants";
import api from "../../api";
import Axios from "axios";

const savedProducts = localStorage.getItem("products");

export const getPageProducts =
  (page, pageSize, time_stamp = 0) =>
  async (dispatch) => {
    const res = await api.product.getAllProducts(page, pageSize, time_stamp);

    if (res && res.status === 200) {
      const products =
        res.data && res.data.filter((product) => product.deleted === false);

      dispatch({
        type: GET_PAGE_PRODUCTS,
        payload: products,
      });
    }

    return Promise.resolve(res);
  };

  export const getProductsCpAndSp = () =>
  async (dispatch) => {
    const res = await api.product.getAllProductsCostAndSellingPrice();

    return Promise.resolve(res);
  };

export const getBranchInventory = (id) => async (dispatch) => {
  const res = await api.product.getBranchInventory(id);

  return Promise.resolve(res);
}

export const getProductHistory = (pageNumber, pageSize,  begin, end) => async (dispatch) => {
  const res = await api.product.getProductHistory(pageNumber, pageSize,  begin, end);

  return Promise.resolve(res);
}

export const getSingleProductHistory = (id, pageNumber, pageSize,  begin, end) => async (dispatch) => {
  const res = await api.product.getSingleProductHistory(id, pageNumber, pageSize,  begin, end);

  return Promise.resolve(res);
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let itemProduct = [];
const addProductToDispatch = (products, action="add") => async (dispatch, getState) => {

  dispatch({
    type: GET_ALL_PRODUCTS,
    payload: itemProduct,
  });

  if(action ==="finish"){
    dispatch({
      type: GET_ALL_PRODUCTS,
      payload: products,
    });
    itemProduct = [];
  }
}

const saveProductCount = (productCount) => async (dispatch, getState) => {
  
  dispatch({
    type: GET_TOTAL_PRODUCTS_COUNT,
    payload: Number(productCount),
  });
}

export const getAllProducts = () => async (dispatch, getState) => {
  const { isNitroActive } = getState().appStore;
  let page = 1;
  let products = [];
  if(isNitroActive){
    console.log("Nitro mode Active...");
  }else{
    for (let i=0; i<page; i++){
      const res = await api.product.getAllProducts(page, 500);
      if(res && res.status === 200){
        dispatch(saveProductCount(res.headers.total));
        if(res.data.length >= 500){//total
          const product = res.data;
          console.log("Getting products...");
          let sortedProducts = product.sort((a, b) => a.name.localeCompare(b.name))
          products.push(...sortedProducts);
          itemProduct.push(...sortedProducts);

          dispatch(addProductToDispatch(sortedProducts));

          page++;
        }else{
          const product = res.data;
          console.log("Finished Getting Products...");
          products.push(...product);
          itemProduct.push(...product);
        
          dispatch(addProductToDispatch(product))
        }
      }
    }
  }
  
  localStorage.setItem("products", JSON.stringify(products));

  // dispatch({
  //   type: GET_ALL_PRODUCTS,
  //   payload: products || (savedProducts ? JSON.parse(savedProducts) : []),
  // });
  
  dispatch(addProductToDispatch(products, "finish"))
 
  const res = {
    data: products,
    status: 200
  }

  return Promise.resolve(res);
};

export const getProductsByCategory = (page, pageSize, category) =>  async (dispatch) => {
  const res = await api.product.getAllProductByCategory(page, pageSize, category);

  // if (res && res.status === 200) {
  //   dispatch({
  //     type: GET_SUPPLIERS,
  //     payload: res.data,
  //   });
  // }

  return Promise.resolve(res);
};

let itemBranchProduct = [];
const addBranchProductToDispatch = (products, action="add") => async (dispatch, getState) => {
  dispatch({
    type: GET_ALL_BRANCH_PRODUCTS,
    payload: itemBranchProduct,
  });

  if(action ==="finish"){
    dispatch({
      type: GET_ALL_BRANCH_PRODUCTS,
      payload: products,
    });
    itemBranchProduct = [];
  }
}

export const getAllBranchProducts =
  (merchantId, branchId, pageNumber, pageSize, category, time_stamp = 0) =>
  async (dispatch, getState) => {
    const { isNitroActive } = getState().appStore;
    let page = pageNumber || 1;
    let products = [];
    if(isNitroActive){
      console.log("Nitro mode Active...");
    }else if(pageNumber, pageSize){
      const res = await api.product.getAllBranchProducts(
        merchantId,
        branchId,
        pageNumber, 
        pageSize,
        category,
        time_stamp,
      );

      if (res && res.status === 200) {
        const products =
          res.data && res.data.filter((product) => product.deleted === false);
  
        dispatch({
          type: GET_ALL_BRANCH_PRODUCTS,
          payload: products,
        });
      }

      return Promise.resolve(res);
    }else{
      for (let i=0; i<page; i++){
        const res = await api.product.getAllBranchProducts(
          merchantId,
          branchId,
          pageNumber = page, 
          pageSize= 500,
          category,
          time_stamp,
        );

        if(res && res.status === 200){
          dispatch(saveProductCount(res.headers["total-product-count"]));
          if(res.data.length >= pageSize){
            const product = res.data;
            console.log("Getting products...");
            let sortedProducts = product.sort((a, b) => a.name.localeCompare(b.name))
            products.push(...sortedProducts);
            itemBranchProduct.push(...sortedProducts);

            dispatch(addBranchProductToDispatch(sortedProducts));

            page++;
          }else{
            const product = res.data;
            console.log("Finished Getting Products...");
            products.push(...product);
            itemBranchProduct.push(...product);
          
            dispatch(addBranchProductToDispatch(product))
          }
        }
      }
  
      // if (res && res.status === 200) {
      //   const products =
      //     res.data && res.data.filter((product) => product.deleted === false);
  
      //   dispatch({
      //     type: GET_ALL_BRANCH_PRODUCTS,
      //     payload: products,
      //   });
      // }
      localStorage.setItem("products", JSON.stringify(products));

      dispatch(addBranchProductToDispatch(products, "finish"))
  
      const res = {
        data: products,
        status: 200
      }

      return Promise.resolve(res);
    }
  };

export const getSuppliers = () => async (dispatch) => {
  const res = await api.productSupplies.getSuppliers();

  if (res && res.status === 200) {
    dispatch({
      type: GET_SUPPLIERS,
      payload: res.data,
    });
  }

  return Promise.resolve(res);
};

export const getProductSupplies = () => async (dispatch) => {
  const res = await api.productSupplies.getProductSupplies();

  if (res && res.status === 200) {
    dispatch({
      type: GET_PRODUCT_SUPPLIES,
      payload: res.data,
    });
  }

  return Promise.resolve(res);
};

export const getProductSuppliesByDuration = (from, to) => async (dispatch) => {
  const res = await api.productSupplies.getSupplyEvents(from, to);

  if (res && res.status === 200) {
    dispatch({
      type: GET_PRODUCT_SUPPLIES,
      payload: res.data,
    });
  }

  return Promise.resolve(res);
};

export const updateProductItemInStore =
  (productsInStore, productToUpdateInStore) => (dispatch) => {
    const updatedProducts =
      productsInStore &&
      productsInStore.map((product) =>
        product.id === productToUpdateInStore.id
          ? productToUpdateInStore
          : product
      );

    dispatch({
      type: UPDATE_PRODUCTS,
      payload: updatedProducts,
    });
  };

export const addNewProduct = (requestPayload) => (dispatch) => {
  return api.product.addNewProduct(requestPayload);
};

export const addSuppliers = (requestPayload) => (dispatch) => {
  return api.productSupplies.addSupplier(requestPayload);
};

export const editSupplies = (id, requestPayload) => (dispatch) => {
  return api.productSupplies.editSupplier(id, requestPayload);
}

export const deleteSuppliers = (id) => (dispatch) => {
  return api.productSupplies.deleteSupplier(id);
}

export const addProductSupplies = (requestPayload) => (dispatch) => {
  return api.productSupplies.supplyProduct(requestPayload);
};

export const editProduct =
  (product_id, requestPayload = {}) =>
  (dispatch) => {
    return api.product.editProduct(product_id, requestPayload);
  };

export const deleteProduct = (product_id) => (dispatch) => {
  return api.product.deleteProduct(product_id);
};

export const sendInventory =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.product.sendInventory(requestPayload);
  };

  export const sendInventoryVariants = (requestPayload = {}) => (dispatch) => {
    console.log("payload", requestPayload);
    return api.product.sendInventoryWithVariant(requestPayload);
  };


export const updateInventory =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.product.updateInventory(requestPayload);
  };

export const importProduct =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.product.importProduct(requestPayload);
  };


