import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, Button } from "antd";
import AccountScreen from "./AccountScreen";
import AccountScreen2 from "./AccountScreen2";
import AddPartners from "./AddPartners";
import ChangeStaffPasswordModal from "./_partials/ChangeStaffPasswordModal";
import FormBuilder from "./FormBuilder";
import GiftCards from "./GiftCards";
import BeepBeepAccount from "./BeepBeepAccount";
import "./index.scss";
import BankScreen from "./BankScreen";
import strings from "../../../../strings";
import StripleLoyalty from "./StripleLoyalty";
import PayStackNuban from "./paystackNuban";
import AddCustomer from "./AddCustomStaff";

const { TabPane } = Tabs;

const Account = (props) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.isFromAppStore) {
      setActiveTab("2");
    }
  }, [location]);

  const isSubscribedToFormBuilder = props.subscribedApps.find(
    (app) => app.app.app_name === "Form Builder"
  )
    ? true
    : false;

  const handleShowChangePassword = () => setShowChangePassword((a) => !a);

  const extraContent =
    activeTab !== "4" ? (
      <Button type="primary" onClick={handleShowChangePassword}>
        {strings.changePassword}
      </Button>
    ) : null;

  const user = props.user;

  return (
    <section className="account">
      <Tabs
        activeKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
        tabBarExtraContent={extraContent}
      >
        <TabPane tab={strings.accountDetails} key="1">
          {/* <AccountScreen /> */}
          <AccountScreen2 />
        </TabPane>
        <TabPane tab={strings.kycBankAccount} key="2">
          <BankScreen />
        </TabPane>
        {user.email === "hello@loystar.co" && (
          <TabPane tab={strings.addPartners} key="3">
            <AddPartners />
          </TabPane>
        )}
        {user.email === "hello@loystar.co" && (
          <TabPane tab={strings.addCustomStaff} key="58">
            <AddCustomer />
          </TabPane>
        )}
        {user.email === "cs@loystar.co" && (
          <TabPane tab={strings.addPartners} key="3">
            <AddPartners />
          </TabPane>
        )}

        {isSubscribedToFormBuilder && (
          <TabPane tab="Form Builder" key="4">
            <FormBuilder />
          </TabPane>
        )}

        <TabPane tab="Gift Cards" key="55">
          <GiftCards />
        </TabPane>
        {props.isBeepBeepActive && <TabPane tab="Beepbeep" key="37">
          <BeepBeepAccount user={user} />
        </TabPane>}
        {props.isStripeLoyaltyActive && <TabPane tab="Stripe Loyalty" key="56">
          <StripleLoyalty />
        </TabPane>}
        {props.isPaystackTerminalActive && <TabPane tab="Paystack Settings" key="57">
          <PayStackNuban />
        </TabPane>}
      </Tabs>

      {showChangePassword ? (
        <ChangeStaffPasswordModal
          handleShowChangePassword={handleShowChangePassword}
          showChangePassword={showChangePassword}
        />
      ) : null}
    </section>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  subscribedApps: state.appStore.subscribedApps,
  isBeepBeepActive: state.appStore.isBeepBeepActive,
  isStripeLoyaltyActive: state.appStore.isStripeLoyaltyActive,
  isPaystackTerminalActive: state.appStore.isPaystackTerminalActive
});

export default connect(mapStateToProps)(Account);
