import { Modal } from 'antd'
import React from 'react'
import strings from '../../../../../strings';
import "./index.scss";

const PurchaseDetails = ({
    open,
    onCancel,
    purchaseOrder,
    user
}) => {
    const productStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        // marginLeft: -30
    }
  return (
    <Modal
        open={open}
        onCancel={onCancel}
        title={`${strings.purchaseOrderHistory} - ${purchaseOrder && purchaseOrder.user && purchaseOrder.user.first_name}`}
        footer={null}
    >
        <div className="images">
        <div style={{display: "flex", alignItems: "center", gap:20}}>

       
        </div>
       
        <p>
          <strong>{strings.supplier}</strong>: {purchaseOrder && purchaseOrder.user && purchaseOrder.user.first_name } {purchaseOrder && purchaseOrder.user && purchaseOrder.user.last_name || ""}
        </p>
        <p>
          <strong>{strings.businessName}</strong>: {purchaseOrder && purchaseOrder.supplier && purchaseOrder.supplier.business_name}
        </p>
        
        <p>
          <strong>{strings.status}</strong>: {purchaseOrder &&purchaseOrder.status}
        </p>
        
        <p>
          <strong>{strings.receivedBy}</strong>: {purchaseOrder && purchaseOrder.purchase_order_histories.length > 0 ? purchaseOrder.purchase_order_histories[purchaseOrder.purchase_order_histories.length - 1].triggered_by || "N/A" : "N/A"}
        </p>

        <div>
            <span>
                <strong>{strings.orderedItems}</strong>
            </span>

            <ul style={{...productStyle, listStyleType: "circle"}} className='supplier_prod'>
                {purchaseOrder && purchaseOrder.purchase_order_items.map((purchase, index) => {
                    return (
                        <li key={index} style={{display: "flex", flexDirection: "column",listStyleType: "circle"}}>
                            <span style={{fontSize: 14}}>
                                {purchase.name} - {user.currency || user.employer.currency} {purchase.price}
                            </span>
                            <div style={{display: "flex", alignItems: "center", gap:8}}>
                                <span  style={{fontSize: 12}}>
                                    <strong>{strings.orderedQty}</strong> - {purchase.ordered_quantity}
                                </span>
                                <span style={{fontSize: 12}}>
                                <strong>{strings.receivedQty}</strong> - {purchase.received_quantity || 0}
                                </span>
                            </div>
                           
                        </li>
                    )
                })}
            </ul>
        </div>
        
      </div>
    </Modal>
  )
}

export default PurchaseDetails